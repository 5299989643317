import React from "react"
import {Input, Label, ListGroupItem} from "reactstrap";
import ListGroup from "reactstrap/lib/ListGroup";
import {SongCategoryApi} from "../../library/api-connector/ApiConnector";
import AlertModal from "../../components/AlertModal";
import SongCategoryType from "../../core/types/SongCategoryType";

type propType = {
    changeSelectedCategory: (categoryId: number) => void,
}

type stateType = {
    selectedCategory?: SongCategoryType,
    possibleCategories: SongCategoryType[]
}

export default class BookMakerCategory extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            possibleCategories: []
        };
    }

    componentDidMount() {
        this.loadCategories()
    }

    loadCategories() {
        SongCategoryApi.getSongCategoryList()
            .then(category => this.setState({possibleCategories: category.data}))
            .catch(() => AlertModal.show({
                header: "Loading categories failed",
                message: "Categories could not be loaded"
            }))
    }

    render() {
        return (
            <ListGroup>
                {this.state.possibleCategories.map((c, i) => {
                    return (
                        <ListGroupItem key={i}>
                            <Label check className="ml-3">
                                <Input type="radio" checked={this.state.selectedCategory === c}
                                       onChange={() => this.setState({selectedCategory: c}, () => this.props.changeSelectedCategory(c.songCategoryId!))}/>
                                {c.name}
                            </Label>
                        </ListGroupItem>
                    );
                })}
                {this.state.possibleCategories.length === 0 ? <span className="text-center">No Categories found</span> : ""}
            </ListGroup>
        );
    }
}
