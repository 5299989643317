import React, { ChangeEvent } from "react";
import { Col, CustomInput, Form, FormGroup, Label } from "reactstrap";
import { AlwaysOfflineStorage } from "../../library/Storage/Storage";

type stateType = {
    isAlwaysOffline: boolean,
}

export default class OptionsAlwaysOffline extends React.Component<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            isAlwaysOffline: AlwaysOfflineStorage.getAlwaysOffline(),
        };
        this.onToggleAlwaysOffline = this.onToggleAlwaysOffline.bind(this);
    }

    onToggleAlwaysOffline(e: ChangeEvent<HTMLInputElement>) {
        const isAlwaysOffline = !this.state.isAlwaysOffline;
        this.setState({isAlwaysOffline});
        AlwaysOfflineStorage.setAlwaysOffline(isAlwaysOffline);
        window.location.reload()
    }

    render() {
        return (<Form>
            <FormGroup row>
                <Label xs={12} sm={2} for="syncAll">Always offline:</Label>
                <Col xs={12} sm={10} className="py-2">
                    <CustomInput type="switch" name="syncAll" label="Force offline mode"
                                 id="alwaysOffline" checked={this.state.isAlwaysOffline} onChange={this.onToggleAlwaysOffline}/>
                </Col>
            </FormGroup>
        </Form>)
    }
}
