import React from "react";

export default class Checker {
    /**
     * Was enter pressed?
     * @param {React.KeyboardEvent} e Event that was triggered by keypress
     * @returns {boolean} if enter was pressed
     */
    public static isEnter(e: React.KeyboardEvent): boolean {
        return e.keyCode === 13 || e.which === 13;
    }

    /**
     * Checks if url is valid playable url
     * @param preview
     */
    public static isValidPreview(preview?: string): boolean {
        if (!preview)
            return false;
        return !!preview.match(/^https?:\/\/(www\.)?youtube.com\/embed\/.+$/)
    }
}
