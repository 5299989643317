import PlaylistType from "../../core/types/PlaylistType";
import IndexedDB from "../IndexedDB";

export default class PlaylistStorage {
    private static Playlist = new IndexedDB().playlist;

    /**
     * Adds new playlist to offline storage
     * @param {PlaylistType} playlist to be added
     */
    static async addPlaylist(playlist: PlaylistType) {
        if (!await this.getPlaylist(playlist.playlistId!))
            await this.Playlist.add(playlist);
    }

    /**
     * Returns all playlists
     * @returns {PlaylistType[]} All offline playlists
     */
    static async getPlaylistList() : Promise<PlaylistType[]>{
        return this.Playlist.toArray();
    }

    /**
     * Gets playlist by id
     * @param {number} playlistId to be returned
     * @returns {PlaylistType} playlist
     */
    static async getPlaylist(playlistId: number) : Promise<PlaylistType | undefined>{
        return this.Playlist.get(playlistId);
    }

    /**
     * Deletes playlists from offline storage
     * @param {number} playlistId to be deleted
     */
    static async deletePlaylist(playlistId: number) {
        this.Playlist.delete(playlistId);
    }
}
