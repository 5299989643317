import React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Alert, Button, Form, FormGroup, Input, Label} from "reactstrap";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoginApi from "../../library/api-connector/api/LoginApi";
import Checker from "../../library/Checker";
import logo from "../../core/resources/Songbook.svg";
import Hasher from "../../core/libraries/Hasher";

type propsType = RouteComponentProps<{ resetToken: string }>;

type stateType = {
    status: "default" | "success" | "error",
    password: string,
}

class LoginResetChangePassword extends React.Component<propsType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            status: "default",
            password: "",
        };
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismissAlert = this.onDismissAlert.bind(this);
    }

    onKeyPress(e: React.KeyboardEvent) {
        if (Checker.isEnter(e) && this.state.password !== "")
            this.onSubmit(e);
    }


    onSubmit(e: React.MouseEvent | React.KeyboardEvent) {
        e.preventDefault();

        LoginApi.resetPassword(this.props.match.params.resetToken, Hasher.hash(this.state.password))
            .then(() => {
                window.location.assign(`${process.env.PUBLIC_URL}/login`)
            })
            .catch(() => {
                this.setState({status: "error"});
            });
    }

    onDismissAlert() {
        this.setState({status: "default"});
    }


    render() {
        return (
            <div className="m-auto" style={{textAlign: "center", maxWidth: "29em"}}>
                <img className="m-2 mt-5" src={logo} style={{maxHeight: "15em", height: "100%"}} alt="Logo"/>
                <div>
                    <h1 className="mt-3 mb-0">Password reset</h1>
                    <p className="text-muted mt-0">Type in you new password, and we'll change it for you!</p>
                    <Form>
                        <FormGroup className="m-2">
                            <Label for="password" className="mr-sm-2">Password</Label>
                            <Input name="password" type="password"
                                   onChange={e => this.setState({password: e.target.value})}
                                   onKeyPress={this.onKeyPress}/>
                        </FormGroup>
                        <Alert className="m-2" color="danger" isOpen={this.state.status === "error"}
                               toggle={this.onDismissAlert}>
                            <FontAwesomeIcon icon={faExclamationCircle}/> Password reset failed! It might be the best to
                            create a new password reset link.
                        </Alert>
                        <Button className="m-2" color="link" href={`${process.env.PUBLIC_URL}/login`}>
                            Sign in
                        </Button>
                        <Button className="m-2" color="primary" onClick={this.onSubmit}
                                disabled={this.state.password === null || this.state.password === ""}>Change
                            Password</Button>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(LoginResetChangePassword);
