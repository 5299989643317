import IndexedDB from "../IndexedDB";
import SongType from "../../core/types/SongType";
import {PAGINATION_SIZE} from "../../Config";
import Fuse from 'fuse.js';
import PaginationType from "../../core/types/PaginationType";
import {SongApiListParameter} from "../api-connector/api/SongApi";

export default class SongStorage {
    private static playlistSong = new IndexedDB().playlist_song;
    private static song = new IndexedDB().song;

    /**
     * Returns all offline songs
     * @param {SongApiListParameter} parameter
     */
    static async getSongList(parameter: SongApiListParameter): Promise<PaginationType<SongType>> {
        let songs: SongType[];

        if (!parameter.playlistId)
            songs = await this.song.toArray()
        else {
            const songsToBeAdded = await this.playlistSong
                .where("playlistId")
                .equals(parameter.playlistId)
                .toArray()
            const songIds = songsToBeAdded.map(s => s.songId);
            songs = await this.song.where("songId").anyOf(songIds).toArray()
        }

        if (parameter.categoryId)
            songs = songs.filter(s => s.categories?.map(s => s.songCategoryId).includes(parameter.categoryId))

        if (parameter.search) {
            const fuse = new Fuse(songs, {
                keys: ["title", "artist", "chords"],
                includeScore: true,
                shouldSort: true,
            })
            songs = fuse.search(parameter.search).map(f => Object.assign(f.item, {score: f.score}));
        } else {
            songs = songs.sort((a, b) => new Date(b.createdAt!).valueOf() - new Date(a.createdAt!).valueOf());
        }
        return {
            count: songs.length,
            data: songs.slice(parameter.offset || 0, !parameter.limit || !parameter.offset ? PAGINATION_SIZE : parameter.limit + parameter.offset)
        };
    }

    /**
     * Returns a song by id
     * @param {number} songId of the song to be returned
     * @returns {SongType} song
     */
    static async getSong(songId: number): Promise<SongType> {
        const song = await this.song.get(songId);
        if (!!song)
            return song;
        else
            throw new Error("Song not found!");
    }
}
