import IndexedDB from "../IndexedDB";
import PaginationType from "../../core/types/PaginationType";
import SongCategoryType from "../../core/types/SongCategoryType";

export default class SongCategoryStorage {
    private static songCategory = new IndexedDB().song_category;

    /**
     * Returns all offline SongCategories
     */
    static async getSongCategoryList(): Promise<PaginationType<SongCategoryType>> {
        const category = await this.songCategory.orderBy("position").toArray();
        return {
            data: category,
            count: category.length
        }
    }
}
