import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import SongType from "../../../core/types/SongType";
import PaginationType from "../../../core/types/PaginationType";
import OfflineChecker from "../../OfflineChecker";
import SongStorage from "../../Storage/SongStorage";

export type SongApiListParameter = {
    limit?: number
    offset?: number
    search?: string
    playlistId?: number
    categoryId?: number
}

export default class SongApi {

    /**
     * Creates a new song
     * @param {SongType} song to be created
     */
    static async createSong(song: SongType): Promise<number> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'POST',
                url: `/song`,
                data: song
            })
                .then(response => resolve(response.data.songId))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Gets an id of a random song
     * @param {string} search filter songs by search string
     * @param {number} playlistId of playlist where random songId should be
     * @param {number} categoryId of where random songId should be
     * @returns {number} random song id
     */
    static async getRandomSongId(search?: string, playlistId?: number, categoryId?: number): Promise<number | undefined> {
        if (!await OfflineChecker.isOffline())
            return new Promise((resolve, reject) => {
                Requester({
                    method: 'GET',
                    url: `/song/random`,
                    params: {search, playlistId, categoryId}
                })
                    .then(response => resolve(response.data.songId))
                    .catch(error => StatusErrorCatcher(error, reject))
            });
        else {
            const songs = (await SongStorage.getSongList({
                limit: 1000000,
                offset: 0,
                playlistId
            })).data;
            const rnd = Math.floor(Math.random() * songs.length);
            return songs[rnd].songId!;
        }
    }

    /**
     * Searches for songs
     * @param {SongApiListParameter} parameter for filtering
     * @returns {PaginationType<SongType[]>} list of songs
     */
    static async listSong(parameter: SongApiListParameter): Promise<PaginationType<SongType>> {
        if (!await OfflineChecker.isOffline())
            return new Promise((resolve, reject) => {
                Requester({
                    method: 'GET',
                    url: `/song`,
                    params: parameter
                })
                    .then(response => resolve({
                        count: parseInt(response.headers.count),
                        data: response.data
                    }))
                    .catch(error => StatusErrorCatcher(error, reject))
            });
        else {
            return SongStorage.getSongList(parameter)
        }
    }

    /**
     * Get a songs
     * @param {number} songId of the song pagination parameter
     */
    static async getSong(songId: number): Promise<SongType> {
        if (!await OfflineChecker.isOffline())
            return new Promise((resolve, reject) => {
                Requester({
                    method: 'GET',
                    url: `/song/${songId}`
                })
                    .then(response => resolve(response.data))
                    .catch(error => StatusErrorCatcher(error, reject))
            });
        else {
            return SongStorage.getSong(songId);
        }
    }

    /**
     * Updates a song
     * @param {SongType} song to be updated
     */
    static async updateSong(song: SongType) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'PUT',
                url: `/song/${song.songId}`,
                data: song
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Deletes a song
     * @param {number} songId song to be deleted
     */
    static async deleteSong(songId: number) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'DELETE',
                url: `/song/${songId}`
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
