import React from "react"
import {Badge, Button, Container, CustomInput, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {BookmakerApiParameters} from "../../library/api-connector/api/BookmakerApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {BookmakerApi} from "../../library/api-connector/ApiConnector";
import PDFFileSaver from "../../library/PDFFileSaver";
import AlertModal from "../../components/AlertModal";
import {TwoSidePrintEnum} from "../../core/types/BookBuilderTypes";
import BookMakerPlaylist from "./BookMakerPlaylist";
import BookMakerSong from "./BookMakerSong";
import BookMakerCategory from "./BookMakerCategory";
import "./BookMaker.scss";

type stateType = & BookmakerApiParameters & {
    downloading: boolean,
    activeTab: "all" | "category" | "playlist" | "song";
}

export default class BookMaker extends React.Component<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: "all",
            downloading: false,
            songnumbers: false,
            pagenumbers: false,
            lyricsonly: false,
        };
        this.downloadPdf = this.downloadPdf.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<stateType>, snapshot?: any) {
        if (prevState.activeTab !== this.state.activeTab)
            this.setState({
                twosideprint: undefined,
                pagenumbers: false,
                songnumbers: false,
                lyricsonly: false,
                categoryId: undefined,
                playlistId: undefined,
                songIds: undefined
            });
    }

    downloadPdf() {
        this.setState({downloading: true});
        BookmakerApi.downloadBook({
            categoryId: this.state.categoryId,
            playlistId: this.state.playlistId,
            songIds: this.state.songIds,
            twosideprint: this.state.twosideprint,
            pagenumbers: this.state.pagenumbers,
            songnumbers: this.state.songnumbers,
            lyricsonly: this.state.lyricsonly
        })
            .then(book => new PDFFileSaver(book).save("Songbook"))
            .catch(() => AlertModal.show({
                header: "Could not create Book",
                message: "Song book could not be created! Please try again later."
            }))
            .finally(() => this.setState({downloading: false}))
    }

    render() {
        return (
            <Container className="my-2">
                <h1>Build your own guitar book</h1>
                <Nav tabs>
                    <NavItem>
                        <NavLink onClick={() => this.setState({activeTab: "all", categoryId : undefined, playlistId: undefined, songIds: []})}
                                 className={this.state.activeTab === "all" ? "active" : undefined}>
                            All Songs
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => this.setState({activeTab: "category", categoryId : undefined, playlistId: undefined, songIds: []})}
                                 className={this.state.activeTab === "category" ? "active" : undefined}>
                            Category
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => this.setState({activeTab: "playlist", categoryId : undefined, playlistId: undefined, songIds: []})}
                                 className={this.state.activeTab === "playlist" ? "active" : undefined}>
                            Playlist
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => this.setState({activeTab: "song", categoryId : undefined, playlistId: undefined, songIds: []})}
                                 className={this.state.activeTab === "song" ? "active" : undefined}>
                            Select Songs
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="mt-2">
                    <Button color="primary" style={{width: "100%"}} onClick={this.downloadPdf}
                            disabled={this.state.downloading
                            || (this.state.activeTab === "category" && !this.state.categoryId)
                            || (this.state.activeTab === "playlist" && !this.state.playlistId)
                            || (this.state.activeTab === "song" && (!this.state.songIds || this.state.songIds.length === 0))}>
                        <FontAwesomeIcon icon={this.state.downloading ? faSpinner : faBook}
                                         spin={this.state.downloading}
                                         className="mr-2"/> {this.state.downloading ? "Building book..." : "Build book"}
                    </Button>
                    <div className="mx-2">
                        <CustomInput type="switch" className="mb-2 mt-2" id="pageNumbers"
                                     checked={this.state.pagenumbers}
                                     label="Add page numbers to book"
                                     onChange={e => this.setState({pagenumbers: e.target.checked})}/>
                        <CustomInput type="switch" className="mb-2 mt-2" id="songNumbers"
                                     checked={this.state.songnumbers}
                                     label="Numerate songs (adds song number to each song title)"
                                     onChange={e => this.setState({songnumbers: e.target.checked})}/>
                        <CustomInput type="switch" className="mb-2 mt-2" id="lyricsonly"
                                     checked={this.state.lyricsonly}
                                     label="Remove chords and insert lyrics only (works only for marked Chords)"
                                     onChange={e => this.setState({lyricsonly: e.target.checked})}/>
                        <p className="mt-2 mb-0">Formats songbook for printing it with two pages per sheet</p>
                        <CustomInput type="radio" className="y-1" id="twoPagePrint_disabled"
                                     checked={this.state.twosideprint === undefined}
                                     label="Disable two page print"
                                     onChange={e => this.setState({twosideprint: undefined})}/>
                        <CustomInput type="radio" className="my-1" id="twoPagePrint_uneqal"
                                     checked={this.state.twosideprint === TwoSidePrintEnum.UNEQUAL}
                                     label="Start songs on uneqal page numbers so you can easily replace songs"
                                     onChange={e => this.setState({twosideprint: TwoSidePrintEnum.UNEQUAL})}/>
                        <CustomInput type="radio" className="my-1" id="twoPagePrint_eqal"
                                     checked={this.state.twosideprint === TwoSidePrintEnum.EQUAL}
                                     label="Start songs on eqal page numbers so you have to flip the paper less during a song"
                                     onChange={e => this.setState({twosideprint: TwoSidePrintEnum.EQUAL})}/>
                    </div>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabid="all" className={this.state.activeTab === "all" ? "active" : undefined}>
                            <div className="text-center m-2">
                                <Badge color="primary">This song book contains all songs currently available!</Badge>
                            </div>
                        </TabPane>
                        <TabPane tabid="category"
                                 className={this.state.activeTab === "category" ? "active" : undefined}>
                            <div className="text-center m-2">
                                <Badge color="primary">Choose a category to create your personalized song book!</Badge>
                            </div>
                            <BookMakerCategory
                                changeSelectedCategory={categoryId => this.setState({categoryId})}/>
                        </TabPane>
                        <TabPane tabid="playlist"
                                 className={this.state.activeTab === "playlist" ? "active" : undefined}>
                            <div className="text-center m-2">
                                <Badge color="primary">Choose the playlist to create your personalized song
                                    book!</Badge>
                            </div>
                            <BookMakerPlaylist
                                changeSelectedPlaylist={playlistId => this.setState({playlistId})}/>
                        </TabPane>
                        <TabPane tabid="song" className={this.state.activeTab === "song" ? "active" : undefined}>
                            <div className="text-center m-2">
                                <Badge color="primary">Choose the songs you want to create your personalized song
                                    book!</Badge>
                            </div>
                            <BookMakerSong
                                changeSelectedSongs={songIds => this.setState({songIds})}/>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        );
    }
}
