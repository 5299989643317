import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import UserRolesEnumType from "../../../core/types/UserRolesEnumType";
import ChordFormattingEnumType from "../../../core/types/ChordFormattingEnumType";

export default class LoginApi {
    /**
     * Checks weather provided credentials are correct
     */
    static async check(): Promise<{ roles: Array<UserRolesEnumType>, chordFormatting: ChordFormattingEnumType }> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: "/login"
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Creates a new reset token
     * @param {string} mail address of the user that wants to have a new reset token
     * @param {string} url that will be shown in the mail
     */
    static async createReset(mail: string, url?: string) : Promise<void> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'POST',
                url: "/login/reset",
                data: {mail},
                params: {url}
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Reset password using reset token
     * @param {string} resetToken previously created and sent via mail
     * @param {string} newPassword
     */
    static async resetPassword(resetToken: string, newPassword: string) {
        return new Promise<void>((resolve, reject) => {
            Requester({
                method: 'POST',
                url: `/login/reset/${resetToken}`,
                data: {password: newPassword}
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

}
