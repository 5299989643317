import React, {createRef} from "react";
import {Button, ButtonDropdown, DropdownItem, DropdownToggle, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import PlaylistType from "../core/types/PlaylistType";
import {PlaylistApi} from "../library/api-connector/ApiConnector";
import AlertModal from "./AlertModal";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import PlaylistEditorModal from "./PlaylistEditorModal";
import {Direction} from "reactstrap/lib/Dropdown";
import OfflineChecker from "../library/OfflineChecker";

type propType = {
    className?: string,
    songId?: number
    checkedPlaylistIds?: number[],
    onChangePlaylist: (playlistId: number, checked: boolean) => Promise<void>,
    dropDirection?: Direction
}

type stateType = {
    allPlaylists: PlaylistType[],
    dropDownShown: boolean,
    newPlaylistName: string,
    offline: boolean,
}

export default class PlaylistDropDown extends React.Component<propType, stateType> {
    private editModalRef = createRef<PlaylistEditorModal>();

    constructor(props: any) {
        super(props);
        this.state = {
            allPlaylists: [],
            dropDownShown: false,
            newPlaylistName: "",
            offline: true
        };
        this.loadPlaylists = this.loadPlaylists.bind(this);
        this.createPlaylist = this.createPlaylist.bind(this);
    }

    async componentDidMount() {
        this.loadPlaylists();
        this.setState({offline: await OfflineChecker.isOffline()})
    }

    loadPlaylists() {
        PlaylistApi.listPlaylist()
            .then(allPlaylists => this.setState({allPlaylists}))
            .catch(() => AlertModal.show({
                header: "Could not load playlists",
                message: "Getting all your playlists failed! Try again later!"
            }));
        this.createPlaylist = this.createPlaylist.bind(this);
    }

    createPlaylist() {
        PlaylistApi
            .createPlaylist({
                name: this.state.newPlaylistName
            })
            .then(playlistId => {
                this.setState({newPlaylistName: ""});
                this.props.onChangePlaylist(playlistId, true)
                    .finally(() => {
                        this.loadPlaylists();
                        this.setState({dropDownShown: false});
                    });
            })
            .catch(() => AlertModal.show({
                header: "Cannot create playlist",
                message: `Failed to create playlist ${this.state.newPlaylistName}! Try again later!`
            }))
    }

    changeChecked(playlistId: number) {
        this.props.onChangePlaylist(playlistId, !this.props.checkedPlaylistIds!.includes(playlistId))
            .finally(() => {
                this.loadPlaylists();
                this.setState({dropDownShown: false});
            });
    }

    render() {

        const DROPDOWNITEM_ADD = (
            <DropdownItem toggle={false} tag="span" style={{minWidth: "190px"}}>
                <InputGroup>
                    <Input type="text" placeholder="Add playlist" value={this.state.newPlaylistName}
                           onChange={e => this.setState({newPlaylistName: e.target.value})}/>
                    <InputGroupAddon addonType="append">
                        <Button onClick={this.createPlaylist} color="primary" size="sm">
                            <FontAwesomeIcon icon={faPlus}/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </DropdownItem>
        );
        const DROPDOWNITEM_ALL = this.state.allPlaylists
            .map((p, i) => {
                return <DropdownItem toggle={false} tag="span" key={i}>
                    <span onClick={() => this.changeChecked(p.playlistId!)}>
                        {this.props.checkedPlaylistIds ?
                            <Input type="checkbox" checked={this.props.checkedPlaylistIds.includes(p.playlistId!)}
                                   onChange={e => this.changeChecked(p.playlistId!)}/>
                            : undefined}
                        {p.name}
                    </span>
                    <Button size="sm" className="float-right" onClick={() => this.editModalRef.current!.open(p)}
                    style={{display: this.state.offline ? "none" : undefined}}>
                        <FontAwesomeIcon icon={faPen}/>
                    </Button>
                </DropdownItem>
            });

        if (DROPDOWNITEM_ALL.length === 0 && !this.props.songId)
            return "";
        return (
            <ButtonDropdown className={this.props.className} isOpen={this.state.dropDownShown}
                            direction={this.props.dropDirection || "left"}
                            toggle={() => this.setState({dropDownShown: !this.state.dropDownShown})}>
                <DropdownToggle caret color={this.props.checkedPlaylistIds && this.props.checkedPlaylistIds.length > 0 ? "primary" : "secondary"}>
                    <FontAwesomeIcon icon={faBookmark}/>
                </DropdownToggle>
                <DropdownMenu className={this.state.dropDownShown ? "show" : undefined} style={{position: "absolute"}}>
                    <DropdownItem header>
                        Playlists
                    </DropdownItem>
                    {DROPDOWNITEM_ALL}
                    {this.props.songId ? DROPDOWNITEM_ADD : undefined}
                </DropdownMenu>
                <PlaylistEditorModal ref={this.editModalRef} onUpdated={this.loadPlaylists}/>
            </ButtonDropdown>
        );
    }
}
