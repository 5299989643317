import React from 'react';
import Router from "./pages/Router";
import {AxiosError} from "axios";
import AlertModal from "./components/AlertModal";
import ApiConnector from "./library/api-connector/ApiConnector";
import {LoginStorage} from "./library/Storage/Storage";
import Synchronizer from "./Synchronizer";
import Theme from "./library/theme/Theme";

export default class App extends React.Component {

    constructor(props: Readonly<{}>) {
        super(props);

        const credentials = LoginStorage.getLogin();
        if (credentials)
            ApiConnector.setLogin(credentials);

        ApiConnector.init(process.env["REACT_APP__api"], (error: AxiosError) => {
            if (["login"].filter(e => window.location.href.includes(`${process.env.PUBLIC_URL}/${e}`)).length > 0) {	// no automatic error handling for array
                throw error;
            } else if (error.response && error.response.status === 401) {
                window.location.assign(`${process.env.PUBLIC_URL}/login?url=${window.location.href}`);
            } else if (error.response && error.response.status === 403) {
                AlertModal.show({
                    header: "Access denied",
                    message: "You do not have access to requested resource!"
                });
                console.error(JSON.stringify(error));
            } else if ((["", "/"].filter(e => `${process.env["REACT_APP__api"]}${e}` === error.config.url).length === 0 && !error.response) 	// no connection error for array
                || (error.response && (error.response.status < 400 || 500 <= error.response.status))) {
                AlertModal.show({header: "Connection Error", message: error.message}, true);
                console.error(JSON.stringify(error));
            }
            throw error;
        });

        Theme.apply();

        Synchronizer.checkAndUpdate();
    }

    render() {
        return <Router/>
    }
}
