import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import PlaylistType from "../../../core/types/PlaylistType";
import OfflineChecker from "../../OfflineChecker";
import PlaylistStorage from "../../Storage/PlaylistStorage";

export default class PlaylistApi {

    /**
     * Creates a new playlist
     * @param {PlaylistType} playlist to be created
     */
    static async createPlaylist(playlist: PlaylistType): Promise<number> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'POST',
                url: `/playlist`,
                data: playlist
            })
                .then(response => resolve(response.data.playlistId))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Returns all playlists
     */
    static async listPlaylist(): Promise<PlaylistType[]> {
        if (!await OfflineChecker.isOffline())
            return new Promise((resolve, reject) => {
                Requester({
                    method: 'GET',
                    url: `/playlist`,
                })
                    .then(response => resolve(response.data))
                    .catch(error => StatusErrorCatcher(error, reject))
            });
        else
            return PlaylistStorage.getPlaylistList();
    }

    /**
     * Get a playlist
     * @param {number} playlistId of the playlist
     */
    static async getPlaylist(playlistId: number): Promise<PlaylistType> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: `/playlist/${playlistId}`
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Updates a playlist
     * @param {PlaylistType} playlist to be updated
     */
    static async updatePlaylist(playlist: PlaylistType) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'PUT',
                url: `/playlist/${playlist.playlistId}`,
                data: playlist
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Deletes a playlist
     * @param {number} playlistId playlist to be deleted
     */
    static async deletePlaylist(playlistId: number) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'DELETE',
                url: `/playlist/${playlistId}`
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Adds song to playlist
     * @param {number} playlistId playlist where the song should be added
     * @param {number} songId of the song to be added
     */
    static async addSongToPlaylist(playlistId: number, songId: number) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'POST',
                url: `/playlist/${playlistId}/song`,
                data: {songId}
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Removes song from playlist
     * @param {number} playlistId playlist where the song should removed from
     * @param {number} songId of the song to be removed
     */
    static async removeSongFromPlaylist(playlistId: number, songId: number) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'DELETE',
                url: `/playlist/${playlistId}/song`,
                data: {songId}
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
