import React from "react";
import {Button, Container, Input, InputGroup, InputGroupAddon, ListGroup, ListGroupItem, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faGripLines, faPlus} from "@fortawesome/free-solid-svg-icons";
import SongCategoryEditor from "./SongCategoryEditor";
import {SortEnd, SortEvent} from "react-sortable-hoc";
import SongCategoryType from "../../core/types/SongCategoryType";
import {SongCategoryApi} from "../../library/api-connector/ApiConnector";
import AlertModal from "../../components/AlertModal";
import {SortableItem, SortableList, SortHandle} from "../../components/SortableList";

type stateType = {
    category: SongCategoryType[],
    status: "loading" | "ide" | "saving" | "error",
    newSongCategoryName: string
}

export default class SongCategoryList extends React.Component<any, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            category: [],
            status: "loading",
            newSongCategoryName: ""
        };
        this.load = this.load.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onReArrange = this.onReArrange.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        SongCategoryApi.getSongCategoryList()
            .then(c => this.setState({category: c.data, status: "ide"}))
            .catch(() => this.setState({status: "error"}));
    }

    onCreate() {
        this.setState({status: "saving"}, () => {
            const category = {
                name: this.state.newSongCategoryName,
                position: this.state.category.length + 1
            };
            SongCategoryApi.createSongCategory(category)
                .then(() => this.setState({category: this.state.category.concat([category]), newSongCategoryName: ""}, this.load))
                .catch(() => AlertModal.show({
                    header: "Creation failed",
                    message: "SongCategory could not be created!",
                    demiss: () => this.setState({status: "ide"})
                }));
        });
    }

    onChange(category: SongCategoryType) {
        this.setState({status: "saving"}, () => {
            SongCategoryApi.updateSongCategory(category.songCategoryId!, category.name)
                .then( this.load)
                .catch(() => AlertModal.show({
                    header: "Update failed",
                    message: "SongCategory could not be updated!",
                    demiss: () => this.setState({status: "ide"})
                }));
        });
    }

    onDelete(songCategoryId: number) {
        this.setState({status: "saving"}, () => {
            AlertModal.show({
                header: "Really delete SongCategory?",
                message: "Do you really want to delete the SongCategory?",
                buttons: [{
                    text: "Yes",
                    handler: () => {
                        SongCategoryApi.deleteSongCategory(songCategoryId)
                            .then(() => this.setState({category: this.state.category.filter(c => c.songCategoryId !== songCategoryId)}))
                            .catch(e => {
                                if (e.status === 409)
                                    AlertModal.show({
                                        header: "Deletion failed",
                                        subHeader: "SongCategory contains songs",
                                        message: "The SongCategory could not be deleted, because it contains songs! Uncheck all this category on all songs, to delete it."
                                    });
                                else
                                    AlertModal.show({
                                        header: "Deletion failed",
                                        message: "Could not delete SongCategory!"
                                    });
                            })
                            .finally(() => this.setState({status: "ide"}));
                    }
                }, {
                    text: "Nein",
                    handler: () => this.setState({status: "ide"})
                }]
            });
        });
    }

    onReArrange(sort: SortEnd, event: SortEvent) {
        const oldCategory: SongCategoryType[] = JSON.parse(JSON.stringify(this.state.category));
        const category: SongCategoryType[] = JSON.parse(JSON.stringify(this.state.category));
        const tmp = category[sort.oldIndex];
        category[sort.oldIndex] = category[sort.newIndex];
        category[sort.newIndex] = tmp;

        this.setState({category, status: "saving"}, () =>
            SongCategoryApi.changeSongCategoryOrder(category.map(c => c.songCategoryId!))
                .then(this.load)
                .catch(() => AlertModal.show({
                    header: "Update failed!",
                    message: "SongCategory could not be updated!",
                    demiss: () => this.setState({category: oldCategory, status: "ide"})
                }))
        );
    }

    render() {
        switch (this.state.status) {
            case "loading":
                return <Container className="text-center mt-5">
                    <Spinner color="primary"/>
                    <span className="ml-2 vertical-super">Loading SongCategory...</span>
                </Container>;
            case "ide":
            case "saving":
                return (
                    <Container className="my-2">
                        <h1>SongCategory</h1>
                        <InputGroup className="mb-2">
                            <Input value={this.state.newSongCategoryName} placeholder="Type a new SongCategory"
                                   style={{textAlign: "center"}}
                                   onChange={e => this.setState({newSongCategoryName: e.target.value})}/>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={this.onCreate}>
                                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>Create
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                        <SortableList useDragHandle onSortEnd={this.onReArrange}>
                            <ListGroup flush>
                                {this.state.category.map((c, i) =>
                                    <SortableItem key={i} index={i}>
                                        <ListGroupItem className="text-center">
                                            <SortHandle>
                                                <FontAwesomeIcon icon={faGripLines} className="mr-2 mt-2 float-left" style={{    cursor: "row-resize"}}/>
                                            </SortHandle>
                                            <SongCategoryEditor category={c} onChange={this.onChange}
                                                                       onDelete={this.onDelete}
                                                                       disableButtons={this.state.status === "saving"}/>
                                        </ListGroupItem>
                                    </SortableItem>
                                )}
                            </ListGroup>
                        </SortableList>
                    </Container>
                );
            case "error":
                return <Container className="my-3 text-center">
                    <h1>Could not load SongCategories!</h1>
                    <Button color="primary" onClick={() => window.history.back()}>
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>Go back
                    </Button>
                </Container>;
        }
    }
}
