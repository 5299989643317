import React from "react";
import {Container} from "reactstrap";
import OptionsTheme from "./OptionsTheme";
import OptionsSync from "./OptionsSync";
import OptionsAlwaysOffline from "./OptionsAlwaysOffline";

const Options : React.FC = props => {
    return (<Container className="my-3">
        <h1>Options</h1>
        <p className="text-muted">Manage your in-app settings!</p>
        <OptionsTheme />
        <OptionsSync />
        <OptionsAlwaysOffline />
    </Container>)
}

export default Options;
