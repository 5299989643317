import React, {ChangeEvent, createRef} from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label
} from "reactstrap";
import SongType from "../../core/types/SongType";
import {faChevronCircleDown, faChevronCircleUp, faSave, faSpinner, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PreviewConverter from "../../library/PreviewConverter";
import Checker from "../../library/Checker";
import SongCategoryType from "../../core/types/SongCategoryType";
import {SongCategoryApi} from "../../library/api-connector/ApiConnector";

const CAPOS = ["", "1st fret", "2nd fret", "3rd fret", "4th fret", "5th fret", "6th fret", "7th fret", "8th fret", "9th fret", "10th fret"];

type propType = {
    song: SongType,
    changeText: string,
    onChange: (song: SongType) => Promise<void>
}

type stateType = SongType & {
    previewShown: boolean,
    status: "saving" | "default",
    chordHeight: number,
    availableSongCategories: SongCategoryType[]
}

export default class SongEditor extends React.Component<propType, stateType> {
    private chords = createRef<HTMLInputElement>();

    constructor(props: any) {
        super(props);
        this.state = Object.assign(this.props.song, {
            previewShown: false,
            status: "default",
            chordHeight: 0,
            availableSongCategories: []
        } as any);
        this.onSave = this.onSave.bind(this);
        this.onCategoryIdChanged = this.onCategoryIdChanged.bind(this);
        this.onChordsChanged = this.onChordsChanged.bind(this);
    }

    componentDidMount() {
        SongCategoryApi.getSongCategoryList()
            .then(categories => this.setState({availableSongCategories: categories.data}, this.onChordsChanged));
    }

    onSave() {
        this.setState({status: "saving"}, () => {
            this.props.onChange({
                songId: this.state.songId,
                title: this.state.title,
                artist: this.state.artist,
                creatorId: this.state.creatorId,
                categories: this.state.categories,
                capo: this.state.capo,
                preview: this.state.preview,
                chords: this.state.chords,
                playlists: this.state.playlists
            })
        });
    }

    onCategoryIdChanged(songCategory: SongCategoryType, contained: boolean) {
        const categories = this.state.categories!.filter(c => c.songCategoryId !== songCategory.songCategoryId);
        if (contained) {
            categories.push(songCategory);
        }
        this.setState({categories});
    }

    async onChordsChanged(e?: ChangeEvent<HTMLInputElement>) {
        if (e) {
            e.persist();
            await new Promise<void>(resolve => this.setState({chords: e.target.value}, resolve));
        }
        this.setState({chordHeight: this.chords.current!.scrollHeight});
    }

    render() {
        return (
            <Container className="mt-2" fluid="md">
                {this.props.children}
                <Form>
                    <ButtonGroup className="float-right">
                        <Button color="danger" onClick={() => window.location.reload()}>
                            <FontAwesomeIcon icon={faTrash} className="mr-2"/>Reset
                        </Button>
                        <Button color="primary" onClick={this.onSave} disabled={this.state.status === "saving"}>
                            <FontAwesomeIcon icon={this.state.status === "default" ? faSave : faSpinner}
                                             spin={this.state.status === "saving"} className="mr-2"/>
                            {this.state.status === "default" ? this.props.changeText : "Saving"}
                        </Button>
                    </ButtonGroup>
                    <FormGroup row>
                        <Label xs={12} sm={1} for="title" style={{marginLeft: -13, marginRight: 13}}>Title: </Label>
                        <Col xs={12} sm={11}>
                            <Input type="text" name="title" value={this.state.title}
                                   onChange={e => this.setState({title: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={1} for="artist">Artist: </Label>
                        <Col xs={12} sm={11}>
                            <Input type="text" name="artist" value={this.state.artist}
                                   onChange={e => this.setState({artist: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <p style={{display: this.state.creator ? undefined : "none"}}>Uploaded
                        by {this.state.creator ? `${this.state.creator.firstName} ${this.state.creator.lastName}` : ""}</p>
                    <FormGroup row>
                        <Label xs={12} sm={1} for="capo">Category: </Label>
                        <Col xs={12} sm={11}>
                            <div className="mt-2">
                                {this.state.availableSongCategories.map((c, i) =>
                                    <FormGroup key={i} check>
                                        <Label check className="ml-2">
                                            <Input type="checkbox"
                                                   checked={this.state.categories?.map(i => i.songCategoryId).includes(c.songCategoryId)}
                                                   onChange={e => this.onCategoryIdChanged(c, e.target.checked)}/>
                                            {c.name}
                                        </Label>
                                    </FormGroup>
                                )}
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={1} for="capo">Capo: </Label>
                        <Col xs={12} sm={11}>
                            {CAPOS.includes(this.state.capo || "") ?
                                <Input type="select" name="capo" value={this.state.capo}
                                       onChange={e => this.setState({capo: e.target.value})}>
                                    {
                                        CAPOS
                                            .map((c, i) => <option key={i} value={c}>{!!c ? c : "Not needed"}</option>)
                                            .concat([<option key={-1} value="Xth fret">Type own Capo</option>])
                                    }
                                </Input>
                                :
                                <Input type="text" name="capo" value={this.state.capo}
                                       onChange={e => this.setState({capo: e.target.value})}/>
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={1} for="preview">Preview: </Label>
                        <Col xs={12} sm={11}>
                            <InputGroup>
                                <Input type="text" name="preview"
                                       value={PreviewConverter.toInput(this.state.preview)}
                                       placeholder="https://youtube.com/watch?v=videoname"
                                       onChange={e => this.setState({preview: PreviewConverter.toPlayable(e.target.value)})}/>
                                <InputGroupAddon addonType="append"
                                                 style={{display: Checker.isValidPreview(this.state.preview) ? undefined : "none"}}>
                                    <Button color="primary"
                                            onClick={() => this.setState({previewShown: !this.state.previewShown})}>
                                        <FontAwesomeIcon
                                            icon={this.state.previewShown ? faChevronCircleUp : faChevronCircleDown}/>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <Collapse isOpen={this.state.previewShown} className="text-center"
                              style={{display: Checker.isValidPreview(this.state.preview) ? undefined : "none"}}>
                        {this.state.previewShown ?
                            <iframe style={{width: "32em", height: "18em"}}
                                    src={`${this.state.preview}?color=white&modestbranding=1&showinfo=0&origin=1`}
                                    frameBorder="0" allowFullScreen title="preview-edit"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"/>
                            : ""}
                    </Collapse>
                    <hr/>
                    <FormGroup>
                        <Label for="chords">Chords: </Label>
                        <Input type="textarea" name="chords" value={this.state.chords} onChange={this.onChordsChanged}
                               innerRef={this.chords}
                               style={{
                                   fontFamily: '"Roboto Mono", monospace',
                                   minHeight: "30em",
                                   height: this.state.chordHeight,
                                   overflow: "hidden",
                                   resize: "none"
                               }}/>
                    </FormGroup>
                </Form>
            </Container>
        );
    }
}

