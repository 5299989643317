import React from 'react';
import {Container, Jumbotron} from "reactstrap";
import News from "./News";
import OfflineChecker from "../../library/OfflineChecker";
import {faFilePdf, faThList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Version from "./Version";
import "./Home.scss";
import backgroundVideo from "./background.mp4";

type stateType = {
    offline: boolean,
}

export default class Home extends React.Component<any, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            offline: true
        }
    }

    async componentDidMount() {
        this.setState({offline: await OfflineChecker.isOffline()})
    }

    render() {
        return (
            <div>
                <video src={backgroundVideo} muted loop autoPlay className="home-video" onContextMenu={e => e.preventDefault()} />
                <Container className="mt-2">
                    <Jumbotron className="p-3 home-transparent">
                        <h1 className="text-primary">Welcome to Songbook!</h1>
                        <p>Here can you save all your favorite guitar songs and take them with you everywhere! <span role="img" aria-label="music">🎵</span></p>
                        <p>
                            Start with searching for a song in the upper bar or just <a
                            href={`${process.env.PUBLIC_URL}/song`}>explore the songs</a>!
                            <br/>
                            If you don't find, what you are looking for, {this.state.offline ? "go online and " : undefined}feel
                            free to {this.state.offline ? "create a new song" :
                            <a href={`${process.env.PUBLIC_URL}/song/new`}>create a new song</a>} by yourself!
                            <br/>
                            Organize yourself by creating own playlists. <FontAwesomeIcon icon={faThList}
                                                                                          style={{color: "var(--primary)"}}/>
                            <br/>
                            {this.state.offline ? "When you are online, you" : "You"} can also create a book (in PDF format)
                            containing all your favorite songs using the {this.state.offline ? "Book Maker" : <a
                            href={`${process.env.PUBLIC_URL}/bookmaker`}>Book
                            Maker</a>}. <FontAwesomeIcon icon={faFilePdf} style={{color: "var(--primary)"}}/>
                            <br/>
                            You need to go to a location without an internet connection? No Problem! Just select "Save
                            Playlist Offline" in the playlist settings and you can access all songs even in the
                            outback! <span role="img" aria-label="camp fire">🏕️🔥</span>
                        </p>
                        <p>
                            I hope you enjoy using this little tool!
                            <br/>
                            Sincerely
                            <br/>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.felix-franz.com">Felix</a>
                        </p>
                    </Jumbotron>
                    <News/>
                    <Version/>
                </Container>
            </div>
        );
    }
}
