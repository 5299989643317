export default class LastSyncStorage {
    /**
     * Gets last sync from local storage or null if there was no previous sync
     * @returns {Date|null}
     */
    static getLastSync(): Date | null {
        const timestamp = window.localStorage.getItem("lastSync");
        if (!timestamp)
            return null;
        else
            return new Date(parseInt(timestamp));
    }
}
