import React from "react";
import {
    Button,
    ButtonGroup,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import PlaylistType from "../core/types/PlaylistType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PlaylistApi } from "../library/api-connector/ApiConnector";
import AlertModal from "./AlertModal";
import PlaylistStorage from "../library/Storage/PlaylistStorage";
import Synchronizer from "../Synchronizer";
import { LastSyncStorage, SongStorage } from "../library/Storage/Storage";

type propType = {
    onUpdated?: () => void
}

type stateType = PlaylistType & {
    showModal: boolean;
    offline: boolean;
    lastSync?: Date | null;
    offlineSongCount?: number,
}

export default class PlaylistEditorModal extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            name: "",
            offline: false
        };
        this.open = this.open.bind(this);
        this.closeAndUpdate = this.closeAndUpdate.bind(this);
        this.changePlaylist = this.changePlaylist.bind(this);
        this.deletePlaylist = this.deletePlaylist.bind(this);
    }

    async componentDidMount() {
        this.setState({
            offlineSongCount: (await SongStorage.getSongList({})).count,
        })
    }

    public async open(playlist: PlaylistType) {
        this.setState(Object.assign(playlist,
            {
                showModal: true,
                offline: !!await PlaylistStorage.getPlaylist(playlist.playlistId!),
                lastSync: LastSyncStorage.getLastSync()
            }));
    }

    private closeAndUpdate() {
        this.setState({ showModal: false });
        if (this.props.onUpdated)
            this.props.onUpdated();
    }

    private async changePlaylist() {
        PlaylistApi.updatePlaylist(this.state)
            .then(this.closeAndUpdate)
            .catch(() => AlertModal.show({
                header: "Could not update playlist!",
                message: `Playlist ${this.state.name} could not be updated!`
            }))
        this.state.offline
            ? await PlaylistStorage.addPlaylist({ playlistId: this.state.playlistId, name: this.state.name })
            : await PlaylistStorage.deletePlaylist(this.state.playlistId!);
        Synchronizer.sync(true);
    }

    private deletePlaylist() {
        AlertModal.show({
            header: `Delete playlist ${this.state.name}`,
            message: `Do you really want to delete the playlist ${this.state.name}?`,
            buttons: [{
                text: "Yes",
                color: "danger",
                handler: () => {
                    PlaylistApi.deletePlaylist(this.state.playlistId!)
                        .then(this.closeAndUpdate)
                        .catch(() => AlertModal.show({
                            header: "Could not delete playlist!",
                            message: `Playlist ${this.state.name} could not be deleted!`
                        }))
                }
            }, "No"]
        })
    }

    render() {
        return (
            <Modal isOpen={this.state.showModal} toggle={() => this.setState({ showModal: !this.state.showModal })}>
                <ModalHeader toggle={() => this.setState({ showModal: !this.state.showModal })}>
                    Edit Playlist {this.state.name}
                </ModalHeader>
                <ModalBody>
                    <FormGroup row>
                        <Label sm="2">Name:</Label>
                        <Col sm="10">
                            <Input type="text" value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })} />
                        </Col>
                    </FormGroup>
                    <CustomInput type="switch" id="offline" name="offline"
                        label={
                            "Save all songs offline" +
                            (
                                this.state.lastSync !== null && typeof (this.state.lastSync) !== "undefined" ?
                                    ` (synced${typeof (this.state.offlineSongCount) !== "undefined" ? ` ${this.state.offlineSongCount} songs` : ""} on ${this.state.lastSync.toLocaleString()})` :
                                    " (nothing synced here)"
                            )
                        }
                        checked={this.state.offline} className="ml-2"
                        onChange={e => this.setState({ offline: e.target.checked })} />
                    <div className="text-right mt-1">
                        <ButtonGroup>
                            <Button color="danger" onClick={this.deletePlaylist}>
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                Delete
                            </Button>
                            <Button color="primary" onClick={this.changePlaylist}>
                                <FontAwesomeIcon icon={faSave} className="mr-2" />
                                Save
                            </Button>
                        </ButtonGroup>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
