import React from "react";
import "./Chord.scss"
import ChordDB from "../core/libraries/chordDB/ChordDB";
import {Tooltip} from "reactstrap";
import ChordPreview from "./ChordPreview";
import {faStickyNote, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChordFormattingEnumType from "../core/types/ChordFormattingEnumType";

type propType = {
    mark: string,
    chord: string,
    id: string,
    chordFormatting: ChordFormattingEnumType
}

type stateType = {
    hasPreview: boolean;
    showPreview: boolean;
    fixPreview: boolean;
}

export default class Chord extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            hasPreview: false,
            showPreview: false,
            fixPreview: false,
        };
        this.onTogglePreview = this.onTogglePreview.bind(this);
    }

    componentDidMount() {
        this.setState({
            hasPreview: ChordDB.getChord(this.props.chordFormatting, this.props.chord).length > 0
        });
    }

    onTogglePreview() {
        if (this.state.hasPreview && !this.state.showPreview)
            this.setState({showPreview: true});
        if (!this.state.fixPreview && this.state.showPreview)
            this.setState({showPreview: false});
    }

    render() {
        return (
            <span>
                <span id={this.props.id} onClick={this.onTogglePreview}
                      className={`chords-chord ${this.state.hasPreview ? "chords-chord-preview pointer" : ""}`}>
                    {this.props.mark}
                </span>
                <Tooltip target={this.props.id} isOpen={this.state.showPreview} toggle={this.onTogglePreview}
                         placement="top" autohide={false} className="chords-chord-tooltip">
                            <FontAwesomeIcon icon={faStickyNote} className="float-left pointer"
                                             style={{color: this.state.fixPreview ? "var(--primary)" : undefined}}
                                             onClick={() => this.setState({fixPreview: !this.state.fixPreview})}/>
                            <FontAwesomeIcon icon={faTimes} className="float-right pointer"
                                             onClick={() => this.setState({fixPreview: false, showPreview: false})}/>
                            <ChordPreview chordFormatting={this.props.chordFormatting} chord={this.props.chord} id={`${this.props.id}-preview`}/>
                </Tooltip>
            </span>
        );
    }
}
