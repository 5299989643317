import React, {createRef} from "react";
import {Spinner} from "reactstrap";

type propType = {
    text?: string
    onGrow: () => Promise<unknown>
    hide?: boolean
    className?: string
}

type stateType = {
    visible: boolean,
    lastHeight: number
}

export default class AutoGrow extends React.Component<propType, stateType> {
    private element = createRef<HTMLDivElement>();

    constructor(props: propType) {
        super(props);
        this.state = {
            visible: false,
            lastHeight: -1
        };
        this.onListToSmall = this.onListToSmall.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.onScroll);

        const initialTrigger = async () => {
            if (this.element.current) {
                await this.onListToSmall();
                setTimeout(initialTrigger, 1000);
            } else
                setTimeout(initialTrigger, 100);
        };
        await initialTrigger();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    async onListToSmall() {
        while (!this.props.hide && this.element.current!.offsetTop <= window.innerHeight)
            await this.props.onGrow();
    }

    onScroll() {
        if (!this.element.current) {
            return;
        }

        if (window.pageYOffset + window.innerHeight >= this.element.current.offsetTop && !this.state.visible) {		// reached scrolling element
            this.setState({visible: true});
            if (window.pageYOffset > this.state.lastHeight) {
                this.setState({lastHeight: window.pageYOffset});
                this.props.onGrow();
            }
        }
        if (window.pageYOffset + window.innerHeight < this.element.current.offsetTop && this.state.visible)			// scrolled away from scrolling element
            this.setState({visible: false});
    }

    render() {
        if (this.props.hide)
            return "";
        else
            return (
                <div className={`text-center ${this.props.className}`} ref={this.element}>
                    <Spinner color="primary"/>
                    <span className="ml-2 vertical-super">{this.props.text}</span>
                </div>
            );
    }
}
