//@ts-ignore
import felixDB from "./db/felix.json";                  // chords defined by me
import TvKDB from "./db/T-vK/chords.complete.json";     // https://github.com/T-vK/chord-collection
// import jGuitarDB from "./db/jguitar.json";              // own scan https://gitlab.com/FelixFranz/songbook/songbook.frontend/-/issues/57#note_517120666
import ChordFormattingEnumType from "../../types/ChordFormattingEnumType";

export type ChordDBChordType = {
    positions: string[],
    fingerings: string[][],
}[];

export default class ChordDB {
    private static chordStrings: string[];

    /**
     * Gets correct chordDB
     * @param {ChordFormattingEnumType} chordFormatting formatting definition
     * @returns {object} chordDB
     */
    private static getDB(chordFormatting : ChordFormattingEnumType){
        switch (chordFormatting){
            case ChordFormattingEnumType.Felix:
                return felixDB;
            case ChordFormattingEnumType["T-vK"]:
                return TvKDB;
            // case ChordFormattingEnumType.jGuitar:
            //     return jGuitarDB;
        }
    }

    /**
     * @param {ChordFormattingEnumType} chordFormatting formatting definition
     * @returns {string[]} all possible chord strings
     */
    static getChordsStrings(chordFormatting : ChordFormattingEnumType): string[] {
        if (!this.chordStrings) {
            this.chordStrings = Object.keys(this.getDB(chordFormatting))
        }
        return this.chordStrings;
    }

    /**
     * @param {ChordFormattingEnumType} chordFormatting formatting definition
     * @param {string} chordName name of the chord
     * @returns {ChordDBChordType} chord
     */
    static getChord(chordFormatting : ChordFormattingEnumType, chordName: string): ChordDBChordType {
        //@ts-ignore
        const chord: ChordDBChordType | undefined = this.getDB(chordFormatting)[chordName];
        if (chord)
            return chord;
        else
            return [];
    }
}
