import React from 'react';
import NewsItem from "./NewsItem";
import {
    faAdjust,
    faBug,
    faDownload,
    faFilePdf,
    faRandom,
    faTag,
    faTasks,
    faThList,
    faTextWidth
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class News extends React.Component {

    render() {
        return (
            <div>
                <div className="home-news-text">
                    <h1 className="text-primary d-inline">News</h1>
                </div>
                <NewsItem title="More synchronization options 🔄" timestamp="Feb 04, 2024">
                    We have just added a Sync-Now button and the synchronization logs to the <a
                        href="/options" >options</a>. <span role="img" aria-label="settings">⚙️</span>
                </NewsItem>
                <NewsItem title="Always offline 📴" timestamp="Feb 03, 2024">
                    If you don't always want the songs to be retrieved online, you can now force the offline mode in the <a
                        href="/options" >options</a>. <span role="img" aria-label="settings">⚙️</span>
                </NewsItem>
                <NewsItem title="Change chord formatting" timestamp="Dec 27, 2021">
                    Since the standard chord formatting, that defines how to hold a chord, has not always fit to us, we have created our own, which will be extended step by step. <FontAwesomeIcon icon={faTextWidth} style={{ color: "var(--primary)" }} />
                    <br />
                    The old one is of course still available and can be accessed via the account settings. <span role="img" aria-label="settings">⚙️</span>
                </NewsItem>
                <NewsItem title="Offline, if you can! 🔌" timestamp="Oct 8, 2021">
                    We have often had the problem that a new song was not yet in a playlist and therefore not available offline. <span role="img" aria-label="offline">🔌</span>
                    <br />
                    Therefore, we have added a new option with which it is possible to synchronize all songs. <FontAwesomeIcon icon={faDownload} style={{ color: "var(--primary)" }} />
                    <br />
                    So there will never be a song that slips through you fingers again. <span role="img" aria-label="search">🔍</span>
                </NewsItem>
                <NewsItem title="Chord cheet sheet 🎸" timestamp="Jul 6, 2021">
                    You need to peek how a chord works? <span role="img" aria-label="eyes">👀</span>
                    <br />
                    No problem - we just have added our chord cheat sheet!
                    Now, in addition to simply clicking on the chord in the song, you can search for chords or view a list of common chords. <span role="img" aria-label="guitar">🎸</span>
                </NewsItem>
                <NewsItem title="Hello darkness, my old friend... 🎵" timestamp="Feb 25, 2021">
                    Once again, we managed to check a few things off the to-do list. <FontAwesomeIcon icon={faTasks} style={{ color: "var(--primary)" }} />
                    <br />
                    To make it easier for you to recognize the chords (and because it just looks cool), we added a dark theme. <FontAwesomeIcon icon={faAdjust} style={{ color: "var(--primary)" }} />
                    <br />
                    Then we made the home screen <span role="img" aria-label="screen">💻</span> a bit nicer and fixed some bugs. <FontAwesomeIcon icon={faBug} style={{ color: "var(--primary)" }} />
                </NewsItem>
                <NewsItem title="Keyboard Shortcuts" timestamp="Jan 19, 2021">
                    Controlling the Songbook is very difficult while playing the guitar. <span role="img" aria-label="guitar">🎸</span>
                    <br />
                    To improve this we have now created keyboard shortcuts <span role="img" aria-label="keyboard">⌨️</span> for auto scrolling.
                    <br />
                    In order to find out the shortcuts, you only need to open the help menu in the autoscroll panel.
                </NewsItem>
                <NewsItem title="Introducing Categories" timestamp="Nov 26, 2020">
                    Since there are quite a lot of songs available by now, we need a better sorting.
                    <br />
                    May I therefore introduce:  The categories <FontAwesomeIcon icon={faTag} style={{ color: "var(--primary)" }} />
                    <br />
                    Each song now has an additional category (e.g. campfire or christmas) by which you can filter the songs.
                    The Bookmaker <span role="img" aria-label="make books">📚</span> also supports categories.
                </NewsItem>
                <NewsItem title="No guitarist? 🎤 - Lyrics only" timestamp="Nov 19, 2020">
                    Making music alone is too boring for you? <span role="img" aria-label="music">🎶</span>
                    Then I have exactly the right Bookmaker option.
                    <br />
                    From now on you can generate a PDF without chords and supply your singers <span role="img" aria-label="sing">🎙️</span> with it.
                </NewsItem>
                <NewsItem title="Chord Preview 🔍" timestamp="Nov 11, 2020">
                    Since it became too annoying to look up chords all the time, I have now integrated a <b>chord preview</b>. <span role="img" aria-label="preview">🔍</span>
                    <br />
                    Now you can simply click <span role="img" aria-label="click">🖱️</span> on a chord in a song and you will get the fingering for that chord!
                </NewsItem>
                <NewsItem title="Enhanced PDFMaker 📘" timestamp="Nov 03, 2020">
                    The PDF generated by BookMaker is also intended to be printed. <span role="img" aria-label="printer">🖨️</span>
                    <br />
                    To reduce paper usage I am happy to introduce the twosideprint function.
                    With this you can select whether a song should start on unequal (for easy replacement) or equal (to reduce flip paper effort) page numbers. <span role="img" aria-label="page">📄️</span>
                    <br />
                    I also provide an option to show/hide page numbers. <span role="img" aria-label="number">🔢️</span>
                </NewsItem>
                <NewsItem title="Better Chord formatting! 🎉" timestamp="Sep 30, 2020">
                    The cord formatting was more hacky <span role="img" aria-label="robot">🤖</span> than good, so I just reimplemented the whole thing. <span role="img" aria-label="grinning">😀</span>
                    <br />
                    I also added a marker for sections <span role="img" aria-label="new">🆕</span> and reworked the PDFBuilder <span role="img" aria-label="hammer">🔨</span>, so the text for the new chord doesn't slide to another page.
                </NewsItem>
                <NewsItem title="Let the Songbook be available even offline" timestamp="Sep 22, 2020">
                    It was a really tough one, so now I'm really glad it's done <span role="img" aria-label="sweat">😅</span>: <b>The offline mode</b>
                    <br />
                    With Offline Modes, the Songbook is now available with the most important functions for viewing and searching songs <span role="img" aria-label="search">🔍</span>, even when your offline.
                    <br />
                    All you have to do is create a playlist and select "Save Playlist Offline" in the playlist settings. <FontAwesomeIcon icon={faDownload} style={{ color: "var(--primary)" }} />
                </NewsItem>
                <NewsItem title="Not to know what to play? – Let the Songbook choose a random song" timestamp="Aug 28, 2020">
                    Since I somehow always played the same songs, I liked a little variety. <span role="img" aria-label="boring">🥱</span>
                    <br />
                    Therefore I integrated the <b>random song</b> function.
                    <br />
                    With it a random song is selected from the complete song database or one of your playlists.
                    <br />
                    You do not like the song? Just click on <FontAwesomeIcon icon={faRandom} style={{ color: "var(--primary)" }} /> again!
                </NewsItem>
                <NewsItem title="Playlists – Order your songs!" timestamp="July 23, 2020">
                    Meanwhile the songbook has so many songs that you won't find the ones you want so fast.
                    <br />
                    Because I was tired of searching the whole database, I <b>integrated playlists</b> <FontAwesomeIcon icon={faThList} style={{ color: "var(--primary)" }} /> into the songbook.
                </NewsItem>
                <NewsItem title="Create your own PDF-Songbook using Bookmaker 📘" timestamp="July 9, 2020">
                    Because the main goal of the Songbook is to save songs to get them everywhere, I created the <b>Bookmaker</b>. <span role="img" aria-label="make books">📚</span>
                    <br />
                    It allows you to <b>create a PDF</b> <FontAwesomeIcon icon={faFilePdf} style={{ color: "var(--primary)" }} /> from a selection of songs or the complete song database.
                    <br />
                    So you can now make music at the campfire even when you are offline... <span role="img" aria-label="camp fire">🏕️🔥</span>
                </NewsItem>
                <NewsItem title="First official release! 🎉" timestamp="July 5, 2020">
                    Now that I've successfully spent a whole day implementing the songbook instead of studying for my exams in two weeks, I'm happy to be able to <b>release the first version</b>. <span role="img" aria-label="party">🎉</span><span role="img" aria-label="music">🎵</span>
                    <br />
                    I can already see that this will be a good and focused examination phase... <span role="img" aria-label="irony">🙈</span>
                </NewsItem>
            </div>
        );
    }
}
