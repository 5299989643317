export enum TwoSidePrintEnum {
    EQUAL = "equal",
    UNEQUAL = "unequal"
}

export type BookBuilderConfig = {
    subtitle?: string;
    twosideprint?: TwoSidePrintEnum;
    pagenumbers?: boolean;
    songnumbers?: boolean;
    lyricsonly?: boolean;
}
