import React from "react";
import UserType from "../../core/types/UserType";
import {Button, Col, Container, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import AccountApi from "../../library/api-connector/api/AccountApi";
import AlertModal from "../../components/AlertModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import ApiConnector from "../../library/api-connector/ApiConnector";
import {ChordFormattingStorage, LoginStorage} from "../../library/Storage/Storage";
import LoginType from "../../core/types/LoginType";
import Hasher from "../../core/libraries/Hasher";
import ChordFormattingEnumType, { ChordFormattingEnumTypeArray } from "../../core/types/ChordFormattingEnumType";

type stateType = UserType & {
    state: "loading" | "show" | "updating"
}

export default class Account extends React.Component<any, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            mail: "",
            firstName: "",
            lastName: "",
            password: "",
            state: "loading",
            chordFormatting: ChordFormattingEnumType.Felix
        }
    }

    componentDidMount() {
        AccountApi.getUser()
            .then(user => this.setState(Object.assign(user, {state: "show"}) as stateType))
            .catch(() => AlertModal.show({
                header: "Failed to load user",
                message: "User could not be loaded!"
            }));
        this.updateData = this.updateData.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    updateData() {
        AccountApi.updateUserData(this.state)
            .then(() => {
                AlertModal.show({
                header: "Account updated",
                message: "Account was successfully updated!"
                });
                ChordFormattingStorage.setChordFormatting(this.state.chordFormatting);
            })
            .catch(() => AlertModal.show({
                header: "Account update failed",
                message: "Account could not be updated!"
            }));
    }

    updatePassword() {
        const login: LoginType = LoginStorage.getLogin()!;
        login.password = Hasher.hash(this.state.password!);
        AccountApi.updateUserPassword(login.password)
            .then(() => AlertModal.show({
                header: "Password updated",
                message: "Password was successfully updated!"
            }))
            .catch(() => AlertModal.show({
                header: "Password update failed",
                message: "Password could not be updated!"
            }));
        ApiConnector.setLogin(login);
        LoginStorage.setLogin(login);
    }

    deleteAccount() {
        AlertModal.show({
            header: "Delete account?",
            subHeader: "All personal data will be lost forever!",
            message: "Do you really want to delete your account?",
            buttons: [{
                text: "Yes",
                color: "danger",
                handler: () => {
                    AccountApi.deleteUser()
                        .then(() => {
                            ApiConnector.removeLogin();
                            LoginStorage.removeLogin();
                            window.location.assign(process.env.PUBLIC_URL)
                        }).catch(() => AlertModal.show({
                        header: "Account deletion failed!",
                        message: "Account could not be deleted!"
                    }))
                }
            }, {
                text: "No",
                color: "secondary"
            }]
        })
    }

    render() {
        switch (this.state.state) {
            case "loading":
                return (
                    <Container className="mt-5 text-center">
                        <Spinner color="primary"/>
                        <span className="ml-2 vertical-super">Loading band leader...</span>
                    </Container>
                );
            case "show":
            case "updating":
                return (
                    <Container className="my-3">
                        <h1>Manage your account</h1>
                        <Form className="mb-2">
                            <FormGroup row>
                                <Label xs={12} sm={2} for="mail">Mail: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="email" name="mail" value={this.state.mail} disabled
                                           onChange={e => this.setState({mail: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="firstName">First name: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="text" name="firstName" value={this.state.firstName}
                                           onChange={e => this.setState({firstName: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="lastName">Last name: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="text" name="lastName" value={this.state.lastName}
                                           onChange={e => this.setState({lastName: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="chordFormatting">Chord Formatting: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="select" name="chordFormatting" value={this.state.chordFormatting}
                                        onChange={e => this.setState({ chordFormatting: e.target.value as ChordFormattingEnumType })}>
                                        {ChordFormattingEnumTypeArray.map((c, i) =>
                                            <option key={i} value={c}>{c}</option>
                                        )}
                                    </Input>
                                    <div className={this.state.chordFormatting === ChordFormattingEnumType.Felix ? "mt-1" : "d-none"}>
                                        Chord formatting for chord previews provided by <a target="_blank" rel="noopener noreferrer" href="https://www.felix-franz.com">Felix Franz</a>.
                                    </div>
                                    <div className={this.state.chordFormatting === ChordFormattingEnumType["T-vK"] ? "mt-1" : "d-none"}>
                                        Chord formatting for chord previews provided by <a target="_blank" rel="noopener noreferrer" href="https://github.com/T-vK/chord-collection">T-vK</a>.
                                    </div>
                                    {/* <div className={this.state.chordFormatting === ChordFormattingEnumType.jGuitar ? "mt-1" : "d-none"}>
                                        Chord formatting for chord previews delivered from <a target="_blank" rel="noopener noreferrer" href="https://jguitar.com/">jGuitar</a>.
                                    </div> */}
                                </Col>
                            </FormGroup>
                            <div className="text-right">
                                <Button color="primary" onClick={this.updateData}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2"/> Save
                                </Button>
                            </div>
                        </Form>
                        <Form className="mb-3">
                            <FormGroup row>
                                <Label xs={12} sm={2} for="password">Password:</Label>
                                <Col xs={12} sm={10}>
                                    <Input type="password" name="password" value={this.state.password}
                                           onChange={e => this.setState({password: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <div className="text-right">
                                <Button color="primary" onClick={this.updatePassword}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2"/> Save
                                </Button>
                            </div>
                        </Form>
                        <div className="text-right">
                            <Button color="danger" onClick={this.deleteAccount}>
                                <FontAwesomeIcon icon={faTrash} className="mr-2"/> Delete Account
                            </Button>
                        </div>
                    </Container>
                );
        }
    }
}
