import crypto from "crypto";
import LoginType from "../types/LoginType";
import UserType from "../types/UserType";

export default class Hasher {

    /**
     * Hashes a string
     * @param {string} data to ba hashed
     * @returns {string} hashed data
     */
    public static hash(data: string): string {
        return crypto
            .createHash('sha512')
            .update(data, 'utf8')
            .digest('hex');
    }

    /**
     * Hashes credential password for sending to the backend
     * @param {LoginType} credentials to be hashed
     * @returns {LoginType} hashed credential
     */
    public static hashLogin(credentials: LoginType): LoginType {
        credentials.password = this.hash(credentials.password);
        return credentials;
    }

    /**
     * Hashes a user for sending to the backend
     * @param {UserType} user to be hashed
     * @returns {UserType} hashed User
     */
    public static hashUser(user: UserType): UserType {
        if (user.password)
            user.password = this.hash(user.password);
        return user;
    }
}
