import React, {useState} from "react";
import {Alert, Container, Input, Row} from "reactstrap";
import ChordDB from "../../core/libraries/chordDB/ChordDB";
import ChordPreview from "../../components/ChordPreview";
import {ChordFormattingStorage} from "../../library/Storage/Storage";

const COMMON_CHORDS = [
    ["C", "Cm"],
    ["D", "D7", "Dm"],
    ["E", "Em"],
    ["F", "Fm"],
    ["G", "Gm"],
    ["A", "Am"],
    ["B", "Bb", "Bm"],
];

const Chords: React.FC = props => {
    const [searchChord, setSearchChord] = useState<string>("")
    const chordFormatting = ChordFormattingStorage.getChordFormatting();

    return (<Container className="my-3 text-center">
        <h1>Chord-Finder</h1>
        <p>We are currently using {ChordFormattingStorage.getChordFormatting()}'s Chord Formatting for providing the chord preview. You can change this in you <a href={`${process.env.PUBLIC_URL}/account`}>Account Settings</a>.</p>
        <Input className="text-center mb-1" type="text" placeholder="Input chord" value={searchChord}
               onChange={e => setSearchChord(e.target.value)}/>
        {
            searchChord === "" ? "" : (
                ChordDB.getChord(chordFormatting, searchChord).length > 0
                    ?
                    <div className="pb-2 mx-auto border border-primary rounded" style={{width: "fit-content"}}>
                        <ChordPreview id="chord-finder" chord={searchChord} chordFormatting={chordFormatting}/>
                    </div>
                    :
                    <Alert color="danger">Chord not found! Please enter another chord or change the <a href={`${process.env.PUBLIC_URL}/account`}>chord formatting</a>.</Alert>
            )
        }
        <h1 className="mt-2">Common Chords</h1>
        <div className="mx-2">
            {COMMON_CHORDS
                .map((r, i) =>
                    <Row key={i} className="mb-3">
                        {r.map((c, j) =>
                            <div key={j} className="mx-auto pb-2 border border-primary rounded">
                                <ChordPreview id={`common-chords-${i}-${j}`} chord={c} chordFormatting={chordFormatting}/>
                            </div>
                        )}
                    </Row>
                )}
        </div>
    </Container>)
};

export default Chords;
