enum UserRolesEnumType {
    PUBLIC = "PUBLIC",
    USER = "USER",
    EDITOR = "EDITOR",
    ADMIN = "ADMIN"
};

export default UserRolesEnumType;

export const UserRolesEnumTypeArray = Object.values(UserRolesEnumType);
