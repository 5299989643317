import React from "react"
import {Input, Label, ListGroupItem} from "reactstrap";
import {PlaylistApi} from "../../library/api-connector/ApiConnector";
import AlertModal from "../../components/AlertModal";
import ListGroup from "reactstrap/lib/ListGroup";
import PlaylistType from "../../core/types/PlaylistType";

type propType = {
    changeSelectedPlaylist: (playlistId: number) => void,
}

type stateType = {
    playlist: PlaylistType[],
    selectedPlaylist?: PlaylistType
}

export default class BookMakerPlaylist extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            playlist: [],
        };
        this.loadPlaylists = this.loadPlaylists.bind(this);
    }

    componentDidMount() {
        this.loadPlaylists()
    }

    loadPlaylists() {
        PlaylistApi.listPlaylist()
            .then(playlist => this.setState({playlist}))
            .catch(() => AlertModal.show({
                header: "Loading playlists failed",
                message: "Playlists could not be loaded"
            }))
    }

    render() {
        return (
            <ListGroup>
                {this.state.playlist.map((p, i) => {
                    return (
                        <ListGroupItem key={i}>
                            <Label check className="ml-3">
                                <Input type="radio" checked={this.state.selectedPlaylist === p}
                                       onChange={() => this.setState({selectedPlaylist: p}, () => this.props.changeSelectedPlaylist(p.playlistId!))}/>
                                {p.name}
                            </Label>
                        </ListGroupItem>
                    );
                })}
                {this.state.playlist.length === 0 ? <span className="text-center">No Playlists found</span> : ""}
            </ListGroup>
        );
    }
}
