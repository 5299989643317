import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import UserType from "../../../core/types/UserType";

export default class AccountApi {
    /**
     * Gets user
     * @returns {UserType} user
     */
    static async getUser(): Promise<UserType> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: "/account"
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Updates User
     * @param {UserType} new user data
     */
    static async updateUser(user: UserType | any): Promise<any> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'PUT',
                url: "/account",
                data: user
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Updates User's data
     * @param {UserType} user new user data
     */
    static async updateUserData(user: UserType): Promise<any> {
        delete user.password;
        await this.updateUser(user);
    }

    /**
     * Updates User's password
     * @param {string} password new password
     */
    static async updateUserPassword(password: string): Promise<any> {
        await this.updateUser({password});
    }

    /**
     * Deletes user
     */
    static async deleteUser(){
        return new Promise((resolve, reject) => {
            Requester({
                method: 'DELETE',
                url: "/account"
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
