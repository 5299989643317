import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import Error from "./Error";
import Page from "./Page";
import SongList from "./song/SongList";
import Login from "./login/Login";
import Logout from "./logout/Logout";
import Home from "./home/Home";
import SongView from "./song/SongView";
import SongCreator from "./song/SongCreator";
import BookMaker from "./bookmaker/BookMaker";
import Account from "./account/Account";
import LoginResetCreate from "./login/LoginResetCreate";
import LoginResetChangePassword from "./login/LoginResetChangePassword";
import User from "./user/User";
import SongCategoryList from "./songcategories/SongCategoryList";
import Options from "./options/Options";
import Chords from "./chords/Chords";


export default class Router extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={`/login/reset/:resetToken`} render={(props) => <Page hideNavbar><LoginResetChangePassword/></Page>}/>
                    <Route path={`/login/reset`} render={(props) => <Page hideNavbar><LoginResetCreate/></Page>}/>
                    <Route path={`/login`} render={(props) => <Page hideNavbar><Login/></Page>}/>
                    <Route path={`/home`} render={(props) => <Page><Home/></Page>}/>
                    <Route path={`/song/new`} render={(props) => <Page><SongCreator/></Page>}/>
                    <Route path={`/song/:songId`} render={(props) => <Page><SongView/></Page>}/>
                    <Route path={`/song`} render={(props) => <Page><SongList/></Page>}/>
                    <Route path={`/chords`} render={(props) => <Page><Chords/></Page>}/>
                    <Route path={`/bookmaker`} render={(props) => <Page><BookMaker/></Page>}/>
                    <Route path={`/user/:userId`} render={(props) => <Page><User/></Page>}/>
                    <Route path={`/user`} render={(props) => <Page><User/></Page>}/>
                    <Route path={`/songcategory`} render={(props) => <Page><SongCategoryList/></Page>}/>
                    <Route path={`/account`} render={(props) => <Page><Account/></Page>}/>
                    <Route path={`/options`} render={(props) => <Page><Options/></Page>}/>
                    <Route path={`/logout`} render={(props) => <Page hideNavbar><Logout/></Page>}/>
                    <Route exact path={`/`}>
                        <Redirect to={`/login`}/>
                    </Route>
                    <Route exact path='*' render={(props) => <Page><Error/></Page>}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
