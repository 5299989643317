import Dexie from "dexie";
import PlaylistType from "../core/types/PlaylistType";
import SongType from "../core/types/SongType";
import SongCategoryType from "../core/types/SongCategoryType";

const DATABASE_NAME = "offline-storage";

export default class IndexedDB extends Dexie {
    public readonly playlist: Dexie.Table<PlaylistType, number>;
    public readonly playlist_song: Dexie.Table<{playlistId: number, songId: number}, number>;
    public readonly song: Dexie.Table<SongType, number>;
    public readonly song_category: Dexie.Table<SongCategoryType, number>;

    /**
     * Creates all databases and tables
     */
    public constructor() {
        super(DATABASE_NAME);
        this.version(1).stores({
            playlist: "playlistId&, name",
            playlist_song: "++id, playlistId, songId",
            song: "songId&, artist, title",
            song_category: "songCategoryId&, name, position",
        });
        this.playlist = this.table("playlist");
        this.playlist_song = this.table("playlist_song");
        this.song = this.table("song");
        this.song_category = this.table("song_category");
    }

    public static async deleteDatabase(){
        await this.delete(DATABASE_NAME);
    }
}
