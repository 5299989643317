import ThemeEnumType from "./ThemeEnumType";
import {ThemeStorage} from "../Storage/Storage";
import "./themes/Bright.scss";
import "./themes/Dark.scss";

window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change" ,() => Theme.apply());

export default class Theme{

    public static set(theme: ThemeEnumType){
        ThemeStorage.setTheme(theme);
        this.apply(theme);
    }

    private  static calcTheme(theme: ThemeEnumType): ThemeEnumType{
        if (theme !== ThemeEnumType.DETECT)
            return theme;
        else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches)
            return ThemeEnumType.BRIGHT;
        else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
            return ThemeEnumType.DARK;
        else
            return ThemeEnumType.BRIGHT;
    }

    public static apply(theme?: ThemeEnumType){
        if (!theme)
            theme = ThemeStorage.getTheme();
        theme = this.calcTheme(theme);
        const themeString = `theme-${theme.toLocaleLowerCase()}`;
        const root = document.getElementsByTagName("html")[0]
        const classes = Array.from(root.classList).filter(c => !c.includes("theme"))
            classes.push(themeString)
        root.className = classes.join(" ");
    }
}
