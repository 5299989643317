import React from 'react';
import {Alert, Button, ButtonGroup, Collapse, Container, Spinner} from "reactstrap";
import SongType from "../../core/types/SongType";
import {PlaylistApi, SongApi} from "../../library/api-connector/ApiConnector";
import {RouteComponentProps, withRouter} from "react-router";
import SongChords from "../../components/SongChords";
import SongControl from "../../components/SongControl";
import {faChevronCircleDown, faChevronCircleUp, faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AlertModal from "../../components/AlertModal";
import Checker from "../../library/Checker";
import SongEditor from "./SongEditor";
import PlaylistDropDown from "../../components/PlaylistDropDown";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import OpenGraph from "../../library/OpenGraph";
import {ChordFormattingStorage, RolesStorage} from "../../library/Storage/Storage";
import UserRolesEnumType from "../../core/types/UserRolesEnumType";
import OfflineChecker from "../../library/OfflineChecker";

type stateType = {
    status: "loading" | "failed" | "show" | "edit" | "editBadPreview" | "editError",
    song?: SongType,
    chordSize: number,
    previewShown: boolean,
    appOffline: boolean,
    wakeLock?: WakeLockSentinel
}

type routeComponentParameter = {
    songId: string
}
type propType = RouteComponentProps<routeComponentParameter>

class SongView extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            status: "loading",
            chordSize: 0.9,
            previewShown: false,
            appOffline: false,
            wakeLock: undefined
        };
        this.loadSong = this.loadSong.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.deleteSong = this.deleteSong.bind(this);
        this.updateSong = this.updateSong.bind(this);
        this.changePlaylist = this.changePlaylist.bind(this);
    }

    componentDidMount() {
        OfflineChecker.isOffline().then(appOffline => this.setState({appOffline}));
        this.loadSong();

        try {
            navigator.wakeLock.request('screen').then(wakeLock => this.setState({wakeLock}));
        } catch (error) {
            console.log("WakeLock is not supported by your browser, your screen may automatically turn of due playing guitar!");
        }
    }

    componentWillUnmount() {
        OpenGraph.setDefault();
        
        if(this.state.wakeLock)
        this.state.wakeLock.release().then(() => this.setState({wakeLock: undefined}));
    }

    async loadSong() {
        try {
            const song = await SongApi.getSong(parseInt(this.props.match.params.songId));
            this.setState({status: "show", song});
            OpenGraph.setSong(song);
        } catch (e) {
            this.setState({status: "failed"});
        }
    }

    onChangeValue(key: string, value: string) {
        const song: any = this.state.song;
        song[key] = value;
        this.setState({song});
    }

    deleteSong() {
        AlertModal.show({
            header: "Really delete?",
            message: `Do you really want to delete ${this.state.song!.title} from  ${this.state.song!.artist}?`,
            buttons: [{
                text: "Yes",
                color: "danger",
                handler: () => SongApi.deleteSong(parseInt(this.props.match.params.songId))
                    .then(() => window.location.assign(`${process.env.PUBLIC_URL}/home`))
                    .catch(() => AlertModal.show({
                        header: "Deletion failed!",
                        message: `${this.state.song!.title} from  ${this.state.song!.artist} could not be deleted!`
                    }))
            }, "No"]
        })
    }

    updateSong(newSong: SongType): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const song = Object.assign(newSong, {creator: this.state.song!.creator});
            SongApi.updateSong(song)
                .then(() => {
                    this.setState({status: "show", song});
                    OpenGraph.setSong(song);
                    resolve();
                })
                .catch(e => {
                    this.setState({status: e.status === 420 ? "editBadPreview" : "editError"});
                    reject();
                })
        });
    }

    async changePlaylist(playlistId: number, checked: boolean) {
        try {
            if (checked)
                await PlaylistApi.addSongToPlaylist(playlistId, parseInt(this.props.match.params.songId));
            else
                await PlaylistApi.removeSongFromPlaylist(playlistId, parseInt(this.props.match.params.songId));
            await this.loadSong();
        } catch (e) {
            AlertModal.show({
                header: "Change playlist failed",
                message: "Could not change playlist!"
            });
        }
    }

    render() {
        switch (this.state.status) {
            case "loading":
                return (
                    <Container className="mt-5 text-center">
                        <Spinner color="primary"/>
                        <span className="ml-2 vertical-super">Get the band ready for the performance...</span>
                    </Container>
                );
            case "failed":
                return (
                    <Container className="mt-5 text-center">
                        <p className="text-center">
                            <span role="img" aria-label="not found">🚫</span> Obviously the song was found! <span
                            role="img" aria-label="sad">😥</span>
                            <br/>
                            Just try a different search!
                        </p>
                    </Container>
                );
            case "show":
                return (
                    <Container className="mt-2" fluid="md">
                        <ButtonGroup className="float-right"
                                     style={{display: (RolesStorage.getRoles() && !RolesStorage.getRoles()!.includes(UserRolesEnumType.EDITOR)) || this.state.appOffline ? "none" : undefined}}>
                            <Button color="danger" onClick={this.deleteSong}>
                                <FontAwesomeIcon icon={faTrash} className="mr-2"/>Delete
                            </Button>
                            <Button color="primary" onClick={() => this.setState({status: "edit"})}>
                                <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>Edit
                            </Button>
                        </ButtonGroup>
                        <h1>{this.state.song!.title}</h1>
                        <h5>by {this.state.song!.artist}</h5>
                        <ButtonGroup className="float-right">
                            <PlaylistDropDown songId={parseInt(this.props.match.params.songId)}
                                              onChangePlaylist={this.changePlaylist}
                                              checkedPlaylistIds={this.state.song!.playlists!.map(p => p.playlistId!)}/>
                            {this.state.song!.preview && !this.state.appOffline ?
                                <Button color="primary"
                                        onClick={() => this.setState({previewShown: !this.state.previewShown})}>
                                    <FontAwesomeIcon icon={faYoutube} className="mr-2"/>
                                    <FontAwesomeIcon
                                        icon={this.state.previewShown ? faChevronCircleUp : faChevronCircleDown}/>
                                </Button>
                                : ""}
                        </ButtonGroup>
                        <p className="mb-1">Uploaded
                            by {this.state.song!.creator!.firstName} {this.state.song!.creator!.lastName}</p>
                        <p className="mb-1">Categories: <span className="font-weight-bold">
                            {this.state.song!.categories!.length > 0 ?
                                this.state.song!.categories!.map((c, i) =>
                                    <span key={i}>
                                        <a href={`${process.env.PUBLIC_URL}/song?categoryId=${c.songCategoryId}`}>{c.name}</a>
                                        {this.state.song!.categories!.length <= i + 1 ? "" : ", "}
                                    </span>
                                )
                                :
                                "Not part of a category"
                            }
                        </span></p>
                        <p className="mb-1">Capo: <span
                            className="font-weight-bold">{this.state.song!.capo || "Not needed"}</span></p>
                        <Collapse isOpen={this.state.previewShown} className="text-center"
                                  style={{display: Checker.isValidPreview(this.state.song!.preview) ? undefined : "none"}}>
                            {this.state.previewShown ?
                                <iframe style={{maxWidth: "90vw", width: "32em", height: "18em"}}
                                        src={`${this.state.song!.preview}?color=white&modestbranding=1&showinfo=0&origin=1&rel=0`}
                                        frameBorder="0" allowFullScreen title="preview-edit"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"/>
                                : ""}
                        </Collapse>
                        <hr/>
                        <SongChords chordFormatting={ChordFormattingStorage.getChordFormatting()} style={{fontSize: `${this.state.chordSize}rem`}}>
                            {this.state.song!.chords}
                            </SongChords>
                        <SongControl textSize={this.state.chordSize}
                                     onChangeSize={chordSize => this.setState({chordSize})}/>
                    </Container>
                );
            case "edit":
            case "editError":
            case "editBadPreview":
                return (
                    <SongEditor song={this.state.song!} changeText="Update" onChange={this.updateSong}>
                        <Alert color="danger" toggle={() => this.setState({status: "edit"})}
                               isOpen={this.state.status === "editError"} className="text-center">
                            Could not save Song! Please try again.
                        </Alert>
                        <Alert color="danger" toggle={() => this.setState({status: "edit"})}
                               isOpen={this.state.status === "editBadPreview"} className="text-center">
                            Bad preview url! Change the url or remove it completely.
                        </Alert>
                    </SongEditor>
                );
        }
    }
}

export default withRouter(SongView);
