import React from "react";
// @ts-ignore
import { draw } from 'vexchords';
import ChordDB from "../core/libraries/chordDB/ChordDB";
import { Button, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import ChordFormattingEnumType from "../core/types/ChordFormattingEnumType";

type propType = {
    chord: string;
    chordFormatting: ChordFormattingEnumType
    id: string;
    className?: string
}

type stateType = {
    maxVersion: number
    version: number
}

export default class ChordPreview extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);

        this.state = {
            maxVersion: 0,
            version: 0,
        };

        this.drawChord = this.drawChord.bind(this);
        this.updateVersion = this.updateVersion.bind(this);
    }

    componentDidMount() {
        this.drawChord();
    }

    componentDidUpdate(prevProps: Readonly<propType>, prevState: Readonly<stateType>, snapshot?: any) {
        if (this.props.chord !== prevProps.chord) {
            this.setState({ version: 0 }, this.drawChord);
        }
        if (this.state.version !== prevState.version)
            this.drawChord();
    }

    drawChord() {
        const chord = ChordDB.getChord(this.props.chordFormatting, this.props.chord);
        this.setState({ maxVersion: chord.length });
        const positions = chord[this.state.version].positions.map(p => {
            if (p === "x")
                return -1
            else
                return parseInt(p)
        });
        const fingerings = chord[this.state.version].fingerings[0].map(f => parseInt(f));

        // move if chords are to high
        let shiftPosition = 0;
        const maxPosition = positions
            .filter(value => value !== -1)
            .reduce((previousValue, currentValue) => Math.max(previousValue, currentValue));
        const minPosition = positions
            .filter(value => value !== -1)
            .reduce((previouseValue, currentValue) => Math.min(previouseValue, currentValue))
        if (maxPosition > 5) {
            shiftPosition = minPosition - 1;
        }

        // set barres
        const barres = [1, 2, 3, 4, 5]
            .filter(f => fingerings.filter(t => f === t).length > 1) // filter for fingers that are on multiple chords
            .map(f => {
                const possibleFingers: number[] = fingerings
                    .map((t, i) => {
                        if (t === f) return i;
                        else return -1;
                    })
                    .filter(t => t !== -1);
                return {
                    fromString: 6 - possibleFingers.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue)),
                    toString: 6 - possibleFingers.reduce((previousValue, currentValue) => Math.max(previousValue, currentValue)),
                    fret: positions[possibleFingers[0]] - shiftPosition
                };
            });

        //draw
        document.getElementById(this.props.id)!.innerHTML = "";
        draw(`#${this.props.id}`, {
            chord: [
                [1, positions[5] === -1 ? "x" : positions[5] - shiftPosition, fingerings[5] !== 0 ? fingerings[5] : undefined],
                [2, positions[4] === -1 ? "x" : positions[4] - shiftPosition, fingerings[4] !== 0 ? fingerings[4] : undefined],
                [3, positions[3] === -1 ? "x" : positions[3] - shiftPosition, fingerings[3] !== 0 ? fingerings[3] : undefined],
                [4, positions[2] === -1 ? "x" : positions[2] - shiftPosition, fingerings[2] !== 0 ? fingerings[2] : undefined],
                [5, positions[1] === -1 ? "x" : positions[1] - shiftPosition, fingerings[1] !== 0 ? fingerings[1] : undefined],
                [6, positions[0] === -1 ? "x" : positions[0] - shiftPosition, fingerings[0] !== 0 ? fingerings[0] : undefined]
            ],
            position: 1 + shiftPosition,
            barres
        }, {
            strokeColor: "var(--primary)"
        });
    }

    updateVersion(increment: number) {
        let version = (this.state.version + this.state.maxVersion + increment) % this.state.maxVersion;
        this.setState({ version });
    }

    render() {
        return (
            <div className={`text-center ${this.props.className ? this.props.className : ""}`}>
                <div style={{ fontWeight: "bold", color: "var(--primary)" }}>{this.props.chord}</div>
                <div style={{ display: this.state.maxVersion > 1 ? undefined : "none" }}>
                    {this.state.version + 1} / {this.state.maxVersion}
                </div>
                <div id={this.props.id} />
                <ButtonGroup style={{ display: this.state.maxVersion > 1 ? undefined : "none" }}>
                    <Button color="primary" size="sm" onClick={() => this.updateVersion(-1)}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} />
                    </Button>
                    <Button color="primary" size="sm" onClick={() => this.updateVersion(1)}>
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}
