
let Synchronizer : {
    checkAndUpdate: () => Promise<void>
    sync: (force? : boolean) => void
}
try {
    //@ts-ignore
    Synchronizer = libs.Synchronizer;
} catch (e) {
    const fn = () => console.log("[Synchronizer] Synchronizer not found! Skipping...")
    const fnp = async () => fn();
    Synchronizer = {
        checkAndUpdate: fnp,
        sync: fn,
    }
}
export default Synchronizer
