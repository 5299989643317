import React, {CSSProperties} from "react";
import "./SongChords.scss";
import ChordFormatter from "../core/libraries/ChordFormatter";
import Chord from "./Chord";
import ChordFormattingEnumType from "../core/types/ChordFormattingEnumType";

type propType = {
    children: string,
    style?: CSSProperties,
    chordFormatting: ChordFormattingEnumType
}

type stateType = {
    songChords?: (string | JSX.Element)[]
}

export default class SongChords extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.updateContent();
    }

    componentDidUpdate(prevProps: Readonly<propType>, prevState: Readonly<stateType>, snapshot?: any) {
        if (this.props !== prevProps)
            this.updateContent();
    }

    updateContent() {
        const songChords = new ChordFormatter(this.props.chordFormatting, this.props.children)
            .format({
                chord: (c, i) => <Chord key={i} id={`Chord-${i}`} mark={c.data} chordFormatting={this.props.chordFormatting} chord={c.chord}/>,
                section: (c, i) => <span key={i} className='chords-section'>{c.data}</span>
            });
        this.setState({songChords});
    }

    render() {
        return (
            <div className="chords" style={this.props.style}>
                {this.state.songChords ? this.state.songChords : this.props.children}
            </div>
        );
    }
}
