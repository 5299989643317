import React from "react";
import {Button, Col, Container, ListGroup, ListGroupItem, Row, Spinner} from "reactstrap";
import UserType from "../../core/types/UserType";
import {UserApi} from "../../library/api-connector/ApiConnector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

type stateType = {
    state: "loading" | "finished" | "error",
    users: UserType[],
}

export default class UserList extends React.Component<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            users: [],
            state: "loading"
        };
    }

    componentDidMount() {
        UserApi.listUsers()
            .then(users => this.setState({users, state: "finished"}))
            .catch(() => this.setState({state: "error"}))
    }

    render() {
        switch (this.state.state) {
            case "loading":
                return (
                    <Container className="my-3">
                        <div className="text-center">
                            <Spinner color="primary"/>
                            <span className="ml-2 vertical-super">Loading users...</span>
                        </div>
                    </Container>
                );
            case "finished":
                return (
                    <Container>
                        <Button className="float-right mt-2" color="primary" href={`${process.env.PUBLIC_URL}/user/new`}>
                            <FontAwesomeIcon icon={faPlus}/> Add
                        </Button>
                        <h1>Users</h1>
                        <ListGroup flush>
                            {
                                this.state.users.map((u, i) => (
                                    <ListGroupItem key={i} href={`${process.env.PUBLIC_URL}/user/${u.userId}`}
                                                   action tag="a">
                                        <Row>
                                            <Col xs="12" md="6">
                                                {u.firstName} {u.lastName}
                                            </Col>
                                            <Col xs="12" md="6">
                                                {u.mail}
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </Container>
                );
            case "error":
                return (
                    <Container className="my-3">
                        <h2>Could not load user list!</h2>
                    </Container>
                );
        }
    }
}


