import React, {PropsWithRef} from 'react';
import {Button} from "reactstrap";
import {withRouter} from "react-router";
import ApiConnector from "../../library/api-connector/ApiConnector";
import logo from "../../core/resources/Songbook.svg"
import IndexedDB from "../../library/IndexedDB";


const Logout = (props: PropsWithRef<{}>) => {

	ApiConnector.removeLogin();
	localStorage.clear();
	IndexedDB.deleteDatabase();

	return (
		<div className="m-auto" style={{textAlign: "center", maxWidth: "29em"}}>
			<img className="m-2 mt-5" src={logo} style={{maxHeight: "15em", height: "100%"}} alt="Logo"/>
			<div className="mt-4">
				<h3>Du wurdest erfolgreich abgemeldet.</h3>
				<Button onClick={() => window.location.replace(`${process.env.PUBLIC_URL}/login`)} color="primary" className="mt-2">
					Erneut einloggen
				</Button>
			</div>
		</div>
	);
};

export default withRouter(Logout);
