import LoginType from "../../core/types/LoginType";

export default class LoginStorage {
    /**
     * Gets saved credentials from local storage or null if nothing was saved previousely
     * @returns {CredentialType|null}
     */
    static getLogin() : LoginType | null {
        const credentials = window.localStorage.getItem("credential");
        if (!credentials)
            return null;
        else
            return  JSON.parse(credentials);
    }

    /**
     * Sets new credentials to local storage
     * @param {CredentialType} credentials to be saved
     */
    static setLogin(credentials: LoginType) {
        window.localStorage.setItem("credential", JSON.stringify(credentials));
    }

    /**
     * Removes Credentials from local storage
     */
    static removeLogin(){
        window.localStorage.removeItem("credential");
    }
}
