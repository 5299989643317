import React, {ChangeEvent} from "react";
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import {ThemeStorage} from "../../library/Storage/Storage";
import ThemeEnumType, {ThemeEnumTypeArray} from "../../library/theme/ThemeEnumType";
import Theme from "../../library/theme/Theme";

type stateType = {
    theme: ThemeEnumType,
}

export default class OptionsTheme extends React.Component<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            theme: ThemeStorage.getTheme()
        };
        this.onChangeTheme = this.onChangeTheme.bind(this);
    }

    onChangeTheme(e : ChangeEvent<HTMLInputElement>) {
        const theme : ThemeEnumType = e.target.value as ThemeEnumType;
        this.setState({theme});
        Theme.set(theme)
    }

    render() {
        return (<Form>
            <FormGroup row>
                <Label xs={12} sm={2} for="theme">Theme: </Label>
                <Col xs={12} sm={10}>
                    <Input type="select" name="theme" value={this.state.theme} onChange={this.onChangeTheme}>
                        {ThemeEnumTypeArray.map((t, i) =>
                            <option key={i}>{t}</option>
                        )}
                    </Input>
                </Col>
            </FormGroup>
        </Form>)
    }
}
