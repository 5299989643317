import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";

export default class DispatcherApi {

    /**
     * Gets Dispatcher information
     */
    static async getDispatcher(): Promise<number> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: `/`,
                headers:{
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            })
                .then(() => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
