import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import UserType from "../../../core/types/UserType";

export default class UserApi {
    /**
     * Create User
     * @param {UserType} user new user data
     * @returns {number} userId of new user
     */
    static async createUser(user: UserType | any): Promise<number> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'POST',
                url: `/user`,
                data: user
            })
                .then(response => resolve(response.data.userId))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Get list of users
     * @returns {UserType[]} users
     */
    static async listUsers(): Promise<UserType[]> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: "/user"
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Get a user
     * @param {number} userId of user to be returned
     * @param {number} userId
     * @returns {UserType} user
     */
    static async getUser(userId: number): Promise<UserType> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: `/user/${userId}`
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Updates User
     * @param {number} userId
     * @param {UserType} user new user data
     */
    static async updateUser(userId: number, user: UserType | any): Promise<any> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'PUT',
                url: `/user/${userId}`,
                data: user
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Updates User's data
     * @param {number} userId
     * @param {UserType} user new user data
     */
    static async updateUserData(userId: number, user: UserType): Promise<any> {
        delete user.password;
        await this.updateUser(userId, user);
    }

    /**
     * Updates User's password
     * @param {string} password new password
     */
    static async updateUserPassword(userId: number, password: string): Promise<any> {
        await this.updateUser(userId, {password});
    }

    /**
     * Deletes user
     * @param {number} userId
     */
    static async deleteUser(userId: number) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'DELETE',
                url: `/user/${userId}`
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
