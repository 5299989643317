import UserRolesEnumType from "../../core/types/UserRolesEnumType";

export default class RolesStorage {
    /**
     * Gets saved roles from local storage or null if nothing was saved previously
     * @returns {Array<UserRolesEnumType> | null}
     */
    static getRoles() : Array<UserRolesEnumType> | null {
        const roles = window.localStorage.getItem("roles");
        if (!roles)
            return null;
        else
            return  JSON.parse(roles);
    }

    /**
     * Sets new user data to local storage
     * @param {Array<UserRolesEnumType>} roles to be saved
     */
    static setRoles(roles: Array<UserRolesEnumType>) {
        window.localStorage.setItem("roles", JSON.stringify(roles));
    }

    /**
     * Removes user data from local storage
     */
    static removeRoles(){
        window.localStorage.removeItem("roles");
    }
}
