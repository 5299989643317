import React from "react";
import {
    Badge,
    Button,
    ButtonGroup,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledButtonDropdown, UncontrolledDropdown
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook, faCog, faGuitar,
    faPlus,
    faRandom,
    faSearch,
    faSignOutAlt, faSlidersH,
    faSpinner,
    faTag,
    faThList,
    faUnlock,
    faUserCircle, faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import Checker from "../library/Checker";
import queryString from "query-string";
import {RouteComponentProps, withRouter} from "react-router";
import logo from "../core/resources/Songbook.svg";
import PlaylistDropDown from "../components/PlaylistDropDown";
import {RolesStorage} from "../library/Storage/Storage";
import UserRolesEnumType from "../core/types/UserRolesEnumType";
import SongApi from "../library/api-connector/api/SongApi";
import AlertModal from "../components/AlertModal";
import OfflineChecker from "../library/OfflineChecker";
import SongCategoryType from "../core/types/SongCategoryType";
import {SongCategoryApi} from "../library/api-connector/ApiConnector";

type stateType = {
    isShown: boolean,
    isOpen: boolean,
    search: string,
    playlistId?: number,
    categoryId?: number,
    loadingRandom: boolean,
    appOffline: boolean,
    songCategories: SongCategoryType[]
}

let bar: NavBar;

export function showNavBar(isShown: boolean) {
    if (bar)
        bar.showNavBar(isShown);
}

class NavBar extends React.Component<RouteComponentProps, stateType> {

    constructor(props: RouteComponentProps) {
        super(props);

        let searchString;
        const querySearch = queryString.parse(window.location.search).search;
        if (!!querySearch)
            searchString = Array.isArray(querySearch) ? querySearch[0] : querySearch;
        let playlistIdString;
        const queryPlaylistId = queryString.parse(window.location.search).playlistId;
        if (!!queryPlaylistId)
            playlistIdString = Array.isArray(queryPlaylistId) ? queryPlaylistId[0] : queryPlaylistId;
        let categoryString;
        const queryCategory = queryString.parse(window.location.search).categoryId;
        if (!!queryCategory)
            categoryString = Array.isArray(queryCategory) ? queryCategory[0] : queryCategory;

        this.state = {
            isShown: true,
            isOpen: false,
            search: searchString || "",
            playlistId: playlistIdString ? parseInt(playlistIdString) : undefined,
            categoryId: categoryString ? parseInt(categoryString) : undefined,
            loadingRandom: false,
            appOffline: false,
            songCategories: []
        };
        this.toggle = this.toggle.bind(this);
        this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
        this.generateQuery = this.generateQuery.bind(this);
        this.changePath = this.changePath.bind(this);
        this.changePlaylist = this.changePlaylist.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.loadRandom = this.loadRandom.bind(this);
        this.showNavBar = this.showNavBar.bind(this);
        bar = this;
    }

    showNavBar(isShown: boolean) {
        this.setState({isShown})
    }

    componentDidMount() {
        OfflineChecker.isOffline().then(appOffline => this.setState({appOffline}));
        SongCategoryApi.getSongCategoryList()
            .then(categories => this.setState({songCategories: categories.data}));
    }

    toggle() {
        this.setState({isOpen: !this.state.isOpen});
    }

    onSearchKeyPress(e: React.KeyboardEvent) {
        if (Checker.isEnter(e))
            this.changePath();
    }

    async changePlaylist(playlistId: number, checked: boolean) {
        let id: number | undefined = playlistId;
        if (!checked)
            id = undefined;
        this.setState({playlistId: id}, this.changePath);
    }

    changeCategory(categoryId?: number) {
        this.setState({categoryId}, this.changePath);
    }

    generateQuery(): string {
        const queryParams: string[] = [];
        if (this.state.search)
            queryParams.push(`search=${this.state.search}`);
        if (this.state.playlistId)
            queryParams.push(`playlistId=${this.state.playlistId}`);
        if (this.state.categoryId)
            queryParams.push(`categoryId=${this.state.categoryId}`);
        return `?${queryParams.join("&")}`;
    }

    changePath() {
        window.location.assign(`${process.env.PUBLIC_URL}/song${this.generateQuery()}`);
    }

    loadRandom() {
        this.setState({loadingRandom: true});
        SongApi.getRandomSongId(this.state.search, this.state.playlistId, this.state.categoryId)
            .then(songId => {
                if (!!songId)
                    window.location.assign(`${process.env.PUBLIC_URL}/song/${songId}${this.generateQuery()}`)

                else
                    AlertModal.show({
                        header: "No random song for this filters",
                        message: "No song exists that matches your filters! Try to remove search, playlist or category to find a random song."
                    });
            })
            .catch(e => AlertModal.show({
                header: "Random song not found",
                message: "A random song could not be found!"
            }))
            .finally(() => this.setState({loadingRandom: false}));
    }

    render() {
        return (
            <Collapse isOpen={this.state.isShown} className="sticky-top">
                <Navbar color="dark" dark expand="md" className="box-shadow">
                    <NavbarBrand href={`${process.env.PUBLIC_URL}/`} style={{padding: 0}}>
                        <img src={logo} style={{height: "2em", filter: "drop-shadow(0px 0px 10px #4444dd)"}}
                             alt="Logo"/>
                    </NavbarBrand>
                    <NavItem className="mx-3 m-auto"
                             style={{display: this.state.appOffline ? undefined : "none", color: "var(--dark)"}}>
                        <Badge style={{fontSize: "1em"}}>Offline Mode</Badge>
                    </NavItem>
                    <NavbarToggler onClick={this.toggle}/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="m-auto text-center" navbar>
                            <NavItem className="my-auto">
                                <InputGroup>
                                    <Input type="text" color="muted" placeholder="Search" className="text-center"
                                           value={this.state.search} onKeyDown={this.onSearchKeyPress}
                                           onChange={e => this.setState({search: e.target.value})}/>
                                    <InputGroupAddon addonType="append">
                                        <ButtonGroup>
                                            <Button color="primary" onClick={this.changePath}
                                                    style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                                                <FontAwesomeIcon icon={faSearch}/>
                                            </Button>
                                            <PlaylistDropDown dropDirection={window.innerWidth < 768 ? "left" : "down"}
                                                              onChangePlaylist={this.changePlaylist}
                                                              checkedPlaylistIds={this.state.playlistId ? [this.state.playlistId!] : []}/>
                                            <UncontrolledButtonDropdown
                                                direction={window.innerWidth < 768 ? "left" : "down"}>
                                                <DropdownToggle caret
                                                                color={this.state.categoryId ? "primary" : "secondary"}>
                                                    <FontAwesomeIcon icon={faTag}/>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem header>Category</DropdownItem>
                                                    <DropdownItem onClick={() => this.changeCategory()}
                                                                  active={!this.state.categoryId}>
                                                        All Categories
                                                    </DropdownItem>
                                                    <DropdownItem divider/>
                                                    {this.state.songCategories.map((c, i) =>
                                                        <DropdownItem key={i}
                                                                      onClick={() => this.changeCategory(c.songCategoryId)}
                                                                      active={c.songCategoryId === this.state.categoryId}>
                                                            {c.name}
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Button color="primary" onClick={this.loadRandom}
                                                    style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                                                <FontAwesomeIcon icon={this.state.loadingRandom ? faSpinner : faRandom}
                                                                 spin={this.state.loadingRandom}/>
                                            </Button>
                                        </ButtonGroup>
                                    </InputGroupAddon>
                                </InputGroup>
                            </NavItem>
                            <NavItem active={!!this.props.match.path.match(/^\/(song\/:songId.*|song)$/)}
                                     className="ml-3">
                                <NavLink href={`${process.env.PUBLIC_URL}/song`}>
                                    <FontAwesomeIcon icon={faThList} className="mr-2"/>Explore
                                </NavLink>
                            </NavItem>
                            <NavItem active={!!this.props.match.path.match(/^\/song\/new$/)}
                                     className="ml-3"
                                     style={{display: (RolesStorage.getRoles() && !RolesStorage.getRoles()!.includes(UserRolesEnumType.EDITOR)) || this.state.appOffline ? "none" : undefined}}>
                                <NavLink href={`${process.env.PUBLIC_URL}/song/new`}>
                                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>Add
                                </NavLink>
                            </NavItem>
                            <NavItem active={!!this.props.match.path.match(/^\/chords$/)}
                                     className="ml-3">
                                <NavLink href={`${process.env.PUBLIC_URL}/chords`}>
                                    <FontAwesomeIcon icon={faGuitar} className="mr-2"/>Chords
                                </NavLink>
                            </NavItem>
                            <NavItem style={{display: this.state.appOffline ? "none" : undefined}}
                                     active={!!this.props.match.path.match(/^\/bookmaker.*$/)}
                                     className="ml-3">
                                <NavLink href={`${process.env.PUBLIC_URL}/bookmaker`}>
                                    <FontAwesomeIcon icon={faBook} className="mr-2"/>Build
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav className="ml-auto text-center" navbar>
                            <UncontrolledDropdown nav inNavbar
                                                  style={{display: (RolesStorage.getRoles() && !RolesStorage.getRoles()!.includes(UserRolesEnumType.ADMIN)) || this.state.appOffline ? "none" : undefined}}
                                                  active={!!this.props.match.path.match(/^\/(user(\/\d+)?|songcategory)$/) || !!this.props.match.path.match(/^\/SongCategory$/)}>
                                <DropdownToggle nav caret style={{whiteSpace: "normal"}}>
                                    <FontAwesomeIcon icon={faUnlock} className="mr-2"/>Admin
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem header>
                                        Admin-Settings
                                    </DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/user`}
                                                  active={!!this.props.match.path.match(/^\/user(\/\d+)?$/)}>
                                        <FontAwesomeIcon icon={faUsersCog} className="mr-2"/>User
                                    </DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/songcategory`}
                                                  active={!!this.props.match.path.match(/^\/songcategory$/)}>
                                        <FontAwesomeIcon icon={faTag} className="mr-2"/>Song-Categories
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar
                                                  active={!!this.props.match.path.match(/^\/(account|options)$/)}>
                                <DropdownToggle nav caret style={{whiteSpace: "normal"}}>
                                    <FontAwesomeIcon icon={faCog} className="mr-2"/>Settings
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/account`}
                                                  style={{display: this.state.appOffline ? "none" : undefined}}
                                                  active={!!this.props.match.path.match(/^\/account$/)}>
                                        <FontAwesomeIcon icon={faUserCircle} className="mr-2"/>Account
                                    </DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/options`}
                                                  active={!!this.props.match.path.match(/^\/options$/)}>
                                        <FontAwesomeIcon icon={faSlidersH} className="mr-2"/>Options
                                    </DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/logout`}
                                                  active={!!this.props.match.path.match(/^\/logout$/)}>
                                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2"/>Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Collapse>
        );
    }
}

export default withRouter(NavBar);
