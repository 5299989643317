import React from 'react';
import Container from "reactstrap/lib/Container";
import queryString from "query-string";
import {SongApi} from "../../library/api-connector/ApiConnector";
import {PAGINATION_SIZE} from "../../Config";
import SongType from "../../core/types/SongType";
import {Button, Col, ListGroup, ListGroupItem, Row, Spinner} from "reactstrap";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OfflineChecker from "../../library/OfflineChecker";
import {RolesStorage} from "../../library/Storage/Storage";
import UserRolesEnumType from "../../core/types/UserRolesEnumType";
import AutoGrow from "../../components/AutoGrow";

type stateType = {
    search?: string,
    playlistId?: number,
    categoryId?: number,
    song: SongType[],
    count: number,
    state: "loading" | "empty" | "finished",
    offline: boolean
}

export default class SongList extends React.Component<{}, stateType> {

    constructor(props: any) {
        super(props);

        let searchString;
        const querySearch = queryString.parse(window.location.search).search;
        if (!!querySearch)
            searchString = Array.isArray(querySearch) ? querySearch[0] : querySearch;
        const queryPlaylistId = queryString.parse(window.location.search).playlistId;
        let playlistString;
        if (!!queryPlaylistId)
            playlistString = Array.isArray(queryPlaylistId) ? queryPlaylistId[0] : queryPlaylistId;
        let categoryString;
        const queryCategory = queryString.parse(window.location.search).categoryId;
        if (!!queryCategory)
            categoryString = Array.isArray(queryCategory) ? queryCategory[0] : queryCategory;

        this.state = {
            search: searchString,
            playlistId: playlistString ? parseInt(playlistString) : undefined,
            categoryId: categoryString ? parseInt(categoryString) : undefined,
            song: [],
            count: 0,
            state: "loading",
            offline: true
        };
        this.loadMore = this.loadMore.bind(this);
    }

    async componentDidMount() {
        SongApi.listSong({
            limit: PAGINATION_SIZE,
            offset: 0,
            search: this.state.search,
            playlistId: this.state.playlistId,
            categoryId: this.state.categoryId
        })
            .then(song => this.setState({
                song: song.data,
                count: song.count,
                state: song.count === 0 ? "empty" : "finished"
            }));
        this.setState({offline: await OfflineChecker.isOffline()});
    }

    loadMore() {
        return new Promise<void>(resolve => {
            SongApi.listSong({
                limit: PAGINATION_SIZE,
                offset: this.state.song.length,
                search: this.state.search,
                playlistId: this.state.playlistId,
                categoryId: this.state.categoryId
            })
                .then(song => this.setState({
                    song: this.state.song.concat(song.data),
                    count: song.count,
                    state: song.count === 0 ? "empty" : "finished"
                }, resolve));
        });
    }

    render() {
        let content: JSX.Element = <div/>;
        switch (this.state.state) {
            case "loading":
                content = (
                    <div className="text-center">
                        <Spinner color="primary"/>
                        <span className="ml-2 vertical-super">Searching songs...</span>
                    </div>
                );
                break;
            case "empty":
                content = (
                    <p className="text-center">
                        <span role="img" aria-label="not found">🚫</span> Obviously no song was found! <span
                        role="img" aria-label="sad">😥</span>
                        <br/>
                        Try a different
                        search{this.state.offline || !RolesStorage.getRoles()!.includes(UserRolesEnumType.EDITOR) ? undefined : " or create a new song"}. <span
                        role="img" aria-label="song">🎵</span>

                        <br/>
                        {this.state.offline ?
                            <span>
                                <br/>
                                Because you're offline <span role="img" aria-label="offline">📴</span> the number of
                                songs might be limited.
                                <br/>
                                Download more playlists next when you are online! <span role="img"
                                                                                        aria-label="globe">🌐</span>
                            </span>
                            :
                            (RolesStorage.getRoles()!.includes(UserRolesEnumType.EDITOR) ?
                                <Button color="primary" className="mt-3" href={`${process.env.PUBLIC_URL}/song/new`}>
                                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>new Song
                                </Button>
                                : undefined)
                        }
                    </p>
                );
                break;
            case "finished":
                const parameters : Array<keyof stateType> = ["search", "playlistId", "categoryId"];
                let parameterString = parameters.filter(t => this.state[t]).map(t => `${t}=${this.state[t]}`).join("&");
                if (parameterString)
                    parameterString = `?${parameterString}`;

                const items = this.state.song.map((s, i) => {
                    return <ListGroupItem key={i} action tag="a"
                                          href={`${process.env.PUBLIC_URL}/song/${s.songId}${parameterString}`}>
                        <Row>
                            <Col xs="12" md="6" style={{fontWeight: "bold"}}>
                                {s.title}
                            </Col>
                            <Col xs="12" md="6">
                                by {s.artist}
                            </Col>
                        </Row>
                    </ListGroupItem>
                });
                content = (
                    <ListGroup flush>
                        {items}
                        <AutoGrow onGrow={this.loadMore} hide={this.state.count <= this.state.song.length}
                                  className="mt-2" text="Loading more songs..."/>
                    </ListGroup>
                );
                break;
        }

        return (
            <Container className="my-3">
                <h1>Song-List</h1>
                {content}
            </Container>
        );
    }
}
