import React from "react";
import UserType from "../../core/types/UserType";
import {Button, Col, Container, Form, FormGroup, Input, Label} from "reactstrap";
import UserRolesEnumType, {UserRolesEnumTypeArray} from "../../core/types/UserRolesEnumType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {UserApi} from "../../library/api-connector/ApiConnector";
import AlertModal from "../../components/AlertModal";
import Hasher from "../../core/libraries/Hasher";
import ChordFormattingEnumType, { ChordFormattingEnumTypeArray } from "../../core/types/ChordFormattingEnumType";

export default class UserNew extends React.Component<{}, UserType> {

    constructor(props: any) {
        super(props);
        this.state = {
            mail: "",
            firstName: "",
            lastName: "",
            password: "",
            userRole: UserRolesEnumType.USER,
            chordFormatting: ChordFormattingEnumType.Felix
        };
        this.createUser = this.createUser.bind(this);
    }

    createUser() {
        const user : UserType = this.state;
        user.password = Hasher.hash(user.password!);
        UserApi.createUser(this.state)
            .then(userId => window.location.assign(`${process.env.PUBLIC_URL}/user/${userId}`))
            .catch(() => AlertModal.show({
                header: "User creation failed",
                message: "User could not be created!"
            }))
    }

    render() {
        return (
            <Container className="my-3">
                <h1>Edit User</h1>
                <Form className="mb-2">
                    <FormGroup row>
                        <Label xs={12} sm={2} for="mail">Mail: </Label>
                        <Col xs={12} sm={10}>
                            <Input type="email" name="title" value={this.state.mail}
                                   onChange={e => this.setState({mail: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={2} for="firstName">First name: </Label>
                        <Col xs={12} sm={10}>
                            <Input type="text" name="firstName" value={this.state.firstName}
                                   onChange={e => this.setState({firstName: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={2} for="lastName">Last name: </Label>
                        <Col xs={12} sm={10}>
                            <Input type="text" name="lastName" value={this.state.lastName}
                                   onChange={e => this.setState({lastName: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={2} for="userRole">Role: </Label>
                        <Col xs={12} sm={10}>
                            <Input type="select" name="userRole" value={this.state.userRole}
                                   onChange={e => this.setState({userRole: e.target.value as UserRolesEnumType})}>
                                {UserRolesEnumTypeArray.filter(r => r !== UserRolesEnumType.PUBLIC).map((r, i) =>
                                    <option key={i} value={r}>{`${r.slice(0, 1)}${r.slice(1, r.length).toLocaleLowerCase()}`}</option>
                                )}
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={2} for="chordFormatting">Chord Formatting: </Label>
                        <Col xs={12} sm={10}>
                            <Input type="select" name="chordFormatting" value={this.state.chordFormatting}
                                    onChange={e => this.setState({chordFormatting: e.target.value as ChordFormattingEnumType})}>
                                {ChordFormattingEnumTypeArray.map((c, i) =>
                                    <option key={i} value={c}>{c}</option>
                                )}
                            </Input>
                            <div className={this.state.chordFormatting === ChordFormattingEnumType.Felix ? "mt-1" : "d-none"}>
                                Chord formatting for chord previews provided by <a target="_blank" rel="noopener noreferrer" href="https://www.felix-franz.com">Felix Franz</a>.
                            </div>
                            <div className={this.state.chordFormatting === ChordFormattingEnumType["T-vK"] ? "mt-1" : "d-none"}>
                                Chord formatting for chord previews provided by <a target="_blank" rel="noopener noreferrer" href="https://github.com/T-vK/chord-collection">T-vK</a>.
                            </div>
                            {/* <div className={this.state.chordFormatting === ChordFormattingEnumType.jGuitar ? "mt-1" : "d-none"}>
                                Chord formatting for chord previews delivered from <a target="_blank" rel="noopener noreferrer" href="https://jguitar.com/">jGuitar</a>.
                            </div> */}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label xs={12} sm={2} for="password">Password:</Label>
                        <Col xs={12} sm={10}>
                            <Input type="password" name="password" value={this.state.password}
                                   onChange={e => this.setState({password: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <div className="text-right">
                        <Button color="primary" onClick={this.createUser}
                                disabled={!this.state.mail || !this.state.firstName || !this.state.lastName || !this.state.password}>
                            <FontAwesomeIcon icon={faSave} className="mr-2"/> Create
                        </Button>
                    </div>
                </Form>
            </Container>
        );
    }
}
