import ThemeEnumType from "../theme/ThemeEnumType";

export default class ThemeStorage {

    /**
     * Get current theme from local storage
     * @returns {ThemeEnumType}
     */
    static getTheme(): ThemeEnumType {
        const theme = window.localStorage.getItem("theme");
        if (theme)
            return JSON.parse(theme) as ThemeEnumType
        else
            return ThemeEnumType.DETECT;
    }

    /**
     * Sets a new theme in local storage
     * @param {ThemeEnumType} theme to be saved
     */
    static setTheme(theme: ThemeEnumType) {
        if (theme !== ThemeEnumType.DETECT)
            window.localStorage.setItem("theme", JSON.stringify(theme));
        else this.removeTheme();
    }

    /**
     * Removes theme from local storage
     */
    static removeTheme() {
        window.localStorage.removeItem("theme");
    }
}
