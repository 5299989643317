import React from 'react';
import {Toast, ToastBody, ToastHeader} from "reactstrap";
import TimeAgo from "react-timeago";
type propType= {
    title: string,
    timestamp: string,
}

export default class NewsItem extends React.Component<propType> {

    render() {
        return (
            <Toast style={{maxWidth: "initial"}} className="home-transparent">
                <ToastHeader>
                    {this.props.title}
                </ToastHeader>
                <ToastBody>
                    <div className="text-muted small"><TimeAgo date={this.props.timestamp}/></div>
                    {this.props.children}
                </ToastBody>
            </Toast>
        );
    }
}
