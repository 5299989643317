import Axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import LoginType from "../../core/types/LoginType";
import VersionApi from "./api/VersionApi";
import LoginApi from "./api/LoginApi";
import AccountApi from "./api/AccountApi";
import SongApi from "./api/SongApi";
import BookmakerApi from "./api/BookmakerApi";
import PlaylistApi from "./api/PlaylistApi";
import UserApi from "./api/UserApi";
import DispatcherApi from "./api/DispatcherApi";
import SongCategoryApi from "./api/SongCategoryApi";

export {
    VersionApi, LoginApi, AccountApi, SongApi, BookmakerApi, PlaylistApi, UserApi, DispatcherApi, SongCategoryApi
};

export const Requester: AxiosInstance = Axios.create();

export default class ApiConnector {
    static credentialInterceptor: number = -1;
    static pathInterceptor: number = -1;
    static errorIntereptor: number = -1;

    /**
     * Sets credentials for future ApiConnector usage
     * @param credentials containing mail and password
     */
    public static setLogin(credentials: LoginType) {
        this.removeLogin();
        this.credentialInterceptor = Requester.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
            const encCred = Buffer.from(`${credentials.mail}:${credentials.password}`).toString('base64');
            config.headers["authorization"] = `Basic ${encCred}`;
            return config;
        }, (config: AxiosRequestConfig): AxiosRequestConfig => {
            return config;
        })
    }

    /**
     * Checks weather credentials were provided using setLogin
     * @returns {boolean} weather credentials are stored
     */
    public static hasLogin(): boolean {
        return this.credentialInterceptor !== -1;
    }

    /**
     * Removes credentials from future ApiConnector usages
     */
    public static removeLogin() {
        if (this.credentialInterceptor !== -1) {
            Requester.interceptors.request.eject(this.credentialInterceptor);
            this.credentialInterceptor = -1;
        }
    }

    /**
     * Initializes ApiConnector connection
     * @param {string} apiUrl base url to connect to the api
     * @param {e: AxiosError) => AxiosError} errorHandler to do anything on error
     */
    public static init(apiUrl: string = "http://127.0.0.1:5000/", errorHandler? : (e: AxiosError) => AxiosError) {
        if (this.pathInterceptor !== -1)
            Requester.interceptors.request.eject(this.pathInterceptor);
        this.pathInterceptor = Requester.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
            config.url = `${apiUrl}${config.url}`;
            return config;
        }, (config: AxiosRequestConfig): AxiosRequestConfig => {
            return config;
        });

        if (this.errorIntereptor !== -1) {
            Requester.interceptors.request.eject(this.errorIntereptor);
            this.errorIntereptor = -1;
        }
        if (errorHandler)
            this.errorIntereptor = Requester.interceptors.response.use(response => response, response => {
                response = errorHandler(response);
                return response;
            })
    }
}

ApiConnector.init();
