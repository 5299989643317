import {Requester} from "../ApiConnector";
import {StatusErrorCatcher} from "../ApiConnectorUtil";
import SongCategoryType from "../../../core/types/SongCategoryType";
import PaginationType from "../../../core/types/PaginationType";
import OfflineChecker from "../../OfflineChecker";
import SongCategoryStorage from "../../Storage/SongCategoryStorage";

export default class SongCategoryApi {

    /**
     * Creates a new SongCategory
     * @param {SongCategoryType} songCategory to be created
     * @returns {number} new songCategoryId
     */
    public static async createSongCategory(songCategory: SongCategoryType): Promise<number> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'POST',
                url: `/songcategory`,
                data: songCategory
            })
                .then(response => resolve(response.data.songCategoryId))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Creates a new SongCategory
     * @param {SongCategoryType} songCategory to be created
     * @returns {number} new songCategoryId
     */
    public static async getSongCategoryList(): Promise<PaginationType<SongCategoryType>> {
        if (!await OfflineChecker.isOffline())
            return new Promise((resolve, reject) => {
                Requester({
                    method: 'GET',
                    url: `/songcategory`
                })
                    .then(response => resolve({
                        data: response.data,
                        count: parseInt(response.headers.count)
                    }))
                    .catch(error => StatusErrorCatcher(error, reject))
            });
        else
            return SongCategoryStorage.getSongCategoryList();
    }

    /**
     * Gets a songCategory
     * @param {number} songCategoryId to be returned
     * @returns {number} songCategory
     */
    public static async getSongCategory(songCategoryId: number): Promise<SongCategoryType> {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: `/songcategory/${songCategoryId}`
            })
                .then(response => resolve(response.data))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Updates songCategory
     * @param {number} songCategoryId to be updated
     * @param {string} newSongCategoryName new name
     */
    public static async updateSongCategory(songCategoryId: number, newSongCategoryName: string) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'PUT',
                url: `/songcategory/${songCategoryId}`,
                data: {name: newSongCategoryName}
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Deletes songCategory
     * @param {number} songCategoryId to be deleted
     */
    public static async deleteSongCategory(songCategoryId: number) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'DELETE',
                url: `/songcategory/${songCategoryId}`
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }

    /**
     * Reorders all SongCategories
     * @param {number[]} songCategoryIds in new order
     */
    public static async changeSongCategoryOrder(songCategoryIds: number[]) {
        return new Promise((resolve, reject) => {
            Requester({
                method: 'PUT',
                url: `/songcategory`,
                data: songCategoryIds
            })
                .then(response => resolve())
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
