import ChordFormattingEnumType from "../types/ChordFormattingEnumType";
import ChordDB from "./chordDB/ChordDB";

const CHORD_MISMATCH = 0.5    // A line will be declared as chord line if at least 0.5 percentage of words are chords
const CHORD_MARKERS_REGEX = /[\\*\\(\\)!]+/g;  // Regex for maker that will be ignored for determining a chord (e.g. Am* --> Am)
const CHORD_WHITESPACE_REGEX =  /(\s|%|\||,|-|\.|\/)+/;  //Regex for whitespaces between chords e.g. Am|C

export enum SongFormatterSplitTypeEnum {
    CHORD,
    SECTION,    //[Verse 1] / [Chorus]
    TEXT,
    WHITESPACE
}

export type SongFormatterSplitType = {
    lineIndex: number
    type: SongFormatterSplitTypeEnum,
    data: string
}

export type SongFormatterSplitChordType = SongFormatterSplitType & {
    chord: string
}

export type FormatOptionsType = {
    chord?: (element: SongFormatterSplitChordType, index: number) => any | string //| JSX.Element
    section?: (element: SongFormatterSplitType, index: number) => any | string //| JSX.Element
    text?: (element: SongFormatterSplitType, index: number) => any | string //| JSX.Element
    whitespace?: (element: SongFormatterSplitType, index: number) => any | string //| JSX.Element
}

export default class ChordFormatter {
    /**
     * @param {string} chords raw/unformatted chords
     */
    public constructor(chordFormatting: ChordFormattingEnumType, chords: string) {
        this.chordFormatting = chordFormatting;
        this.rawChords = chords;
    }

    private rawChords: string;
    private chordFormatting: ChordFormattingEnumType;

    public split(): SongFormatterSplitType[] {
        const chords: (SongFormatterSplitType | SongFormatterSplitChordType)[] = [];
        this.rawChords.split(/[\r\n]/g)
            .forEach((line, i) => {
                line = `${line}\n`;
                const text = line.split(/\s+/).filter(s => s);
                if (text.map(t => t.replace(CHORD_WHITESPACE_REGEX, "")).filter(t => t !== "" ).filter(m => ChordDB.getChordsStrings(this.chordFormatting).includes(m)).length >= CHORD_MISMATCH * text.map(t => t.replace(CHORD_WHITESPACE_REGEX, "")).filter(t => t !== "" ).length) {      // A line containing chords
                    let curString = "";
                    let curType: SongFormatterSplitTypeEnum | undefined;
                    for (let c of line) {
                        if ((curType === SongFormatterSplitTypeEnum.WHITESPACE && c.match(CHORD_WHITESPACE_REGEX))    // ongoing whitespace
                            || (curType === SongFormatterSplitTypeEnum.CHORD && !c.match(CHORD_WHITESPACE_REGEX)))        // ongoing chord
                            curString = `${curString}${c}`;
                        else {
                            chords.push({
                                lineIndex: i,
                                data: curString,
                                type: curType!,
                                chord: curType === SongFormatterSplitTypeEnum.WHITESPACE ? undefined : curString.replace(CHORD_MARKERS_REGEX, "")
                            });
                            curString = c;
                            curType = c.match(CHORD_WHITESPACE_REGEX) ? SongFormatterSplitTypeEnum.WHITESPACE : SongFormatterSplitTypeEnum.CHORD
                        }
                    }
                    if (curString)
                        chords.push({
                            lineIndex: i,
                            data: curString,
                            type: curType!
                        })
                } else if (line.match(/^([\S\s]*)(\[.+\])([\S\s]*)$/)) {       // A line containing section name
                    const match = line.match(/^([\S\s]*)(\[.+\])([\S\s]*)$/);
                    if (match![1])
                        chords.push({
                            lineIndex: i,
                            data: match![1],
                            type: SongFormatterSplitTypeEnum.WHITESPACE
                        })
                    chords.push({
                        lineIndex: i,
                        data: match![2],
                        type: SongFormatterSplitTypeEnum.SECTION
                    })
                    if (match![3])
                        chords.push({
                            lineIndex: i,
                            data: match![3],
                            type: SongFormatterSplitTypeEnum.WHITESPACE
                        })
                } else                                                      // A normal song text
                    chords.push({
                        lineIndex: i,
                        data: line,
                        type: SongFormatterSplitTypeEnum.TEXT
                    })
            })
        return chords;
    }

    public format(options?: FormatOptionsType): (any | string /* JSX.Element */)[] {
        if (!options)
            options = {}
        const data = this.split().map((l, i) => {
            switch (l.type) {
                case SongFormatterSplitTypeEnum.CHORD:
                    return options?.chord ? options.chord(l as SongFormatterSplitChordType, i) : l.data
                case SongFormatterSplitTypeEnum.SECTION:
                    return options?.section ? options.section(l, i) : l.data;
                case SongFormatterSplitTypeEnum.TEXT:
                    return options?.text ? options.text(l, i) : l.data;
                case SongFormatterSplitTypeEnum.WHITESPACE:
                    return options?.whitespace ? options.whitespace(l, i) : l.data;
                default:
                    return l.data;
            }
        });
        return data;
    }
}
