import React from 'react';
import {Button} from 'reactstrap'
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class Error extends React.Component {

	render() {
		if (!window.location.toString().endsWith(`${process.env.PUBLIC_URL}/error`)) {
			window.location.replace(`${process.env.PUBLIC_URL}/error`);
			return <div></div>;
		}
		else
			return (
				<div>
					<div style={{marginTop: "1em", marginBottom: "1em"}} className="container">
						<h1>Error</h1>
						<p>The page you are looking for does not exist!</p>
						<Button color="primary" href={`${process.env.PUBLIC_URL}/`}><FontAwesomeIcon icon={faHome} /> Go back on stage</Button>
					</div>
				</div>
			);
	}
}
