import React from "react";
import {Button, ButtonGroup, Input} from "reactstrap";
import {faCheck, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SongCategoryType from "../../core/types/SongCategoryType";

type propType = {
    category: SongCategoryType,
    onChange(category: SongCategoryType): void
    onDelete: (songCategoryId: number) => void
    disableButtons?: boolean
}

type stateType = SongCategoryType & {
    edit: boolean
}

export default class SongCategoryEditor extends React.Component<propType, stateType> {

    constructor(props: propType) {
        super(props);
        this.state = Object.assign(props.category, {
            edit: false
        });
        this.onClick = this.onClick.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<propType>, prevState: Readonly<stateType>, snapshot?: any) {
        if (prevProps.category !== this.props.category)
            this.setState(this.props.category);
    }

    onClick() {
        if (this.state.edit)
            this.props.onChange({
                name: this.state.name,
                position: this.state.position,
                songCategoryId: this.state.songCategoryId
            });
        this.setState({edit: !this.state.edit});
    }

    render() {
        return <span>
			<ButtonGroup className="float-right">
				<Button color="primary" onClick={this.onClick} disabled={this.props.disableButtons}>
					<FontAwesomeIcon icon={this.state.edit ? faCheck : faPen} className="mr-2"/>
                    {this.state.edit ? "Save" : "Edit"}
				</Button>
					<Button color="danger" onClick={() => this.props.onDelete(this.state.songCategoryId!)}
                            disabled={this.props.disableButtons}>
						<FontAwesomeIcon icon={faTrash} className="mr-2"/>Delete
					</Button>
			</ButtonGroup>
            {this.state.edit ?
                <Input type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})}
                       style={{width: `calc(100% - 155px - 95px)`, textAlign: "center"}}
                       className="mx-auto"/>
                :
                <div className="mt-2">{this.state.name}</div>
            }
			</span>;
    }
}
