import React from "react";
import "./SongContol.scss";
import {Badge, Col, ListGroupItem, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

type propType = {
    button: IconDefinition | IconDefinition[],
    keyboard: string | string[],
    text: string
}

export default class SongControlHelpModalItem extends React.Component<propType> {

    render() {
        const button = Array.isArray(this.props.button) ? this.props.button : [this.props.button];
        const key = Array.isArray(this.props.keyboard) ? this.props.keyboard : [this.props.keyboard];
        return (<ListGroupItem className="p-1">
            <Row className="text-center">
                <Col xs={3} sm={2} className="my-auto">
                    {button.map((b, i) =>
                        <Badge color="primary" key={i} className="mx-1"><FontAwesomeIcon icon={b}/></Badge>
                    )}
                </Col>
                <Col xs={3} sm={2} className="my-auto">
                    {key.map((k, i) =>
                        <Badge color="info" className="font-weight-bolder text-monospace mx-1" key={i}>{k}</Badge>
                    )}
                </Col>
                <Col xs={6} sm={8} className="my-auto">{this.props.text}</Col>
            </Row>
        </ListGroupItem>);
    }
}
