import React from "react";
import {Alert} from "reactstrap";
import SongType from "../../core/types/SongType";
import {SongApi} from "../../library/api-connector/ApiConnector";
import SongEditor from "./SongEditor";

type statusType = {
    status: "create" | "badPreview" | "error"
}

export default class SongCreator extends React.Component<{}, statusType> {

    constructor(props: any) {
        super(props);
        this.state = {
            status: "create"
        };
        this.createSong = this.createSong.bind(this);
    }

    async createSong(song: SongType) :Promise<void> {
        return new Promise<void>((resolve, reject) => {
            SongApi.createSong(song)
                .then(songId => {
                    window.location.assign(`${process.env.PUBLIC_URL}/song/${songId}`);
                    resolve();
                })
                .catch(e => {
                    this.setState({status: e.status === 420 ? "badPreview" : "error"});
                    reject();
                })
        });
    }

    render() {
        return (
            <SongEditor song={{
                title: "",
                artist: "",
                capo: "",
                categories: [],
                preview: "",
                chords: ""
            }}
                        changeText="Create" onChange={this.createSong}>
                <Alert color="danger" toggle={() => this.setState({status: "create"})}
                       isOpen={this.state.status === "error"} className="text-center">
                    Could not save Song! Please try again.
                </Alert>
                <Alert color="danger" toggle={() => this.setState({status: "create"})}
                       isOpen={this.state.status === "badPreview"} className="text-center">
                    Bad preview url! Change the url or remove it completely.
                </Alert>
            </SongEditor>
        );
    }
}
