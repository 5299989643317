import FileSaver from "file-saver";

export default class PDFFileSaver {
    data : string;

    constructor(data: string){
        this.data = data;
    }

    save(name: string){
        const byteString = atob(this.data);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        FileSaver.saveAs(new Blob([ab]), `${name}.pdf`);
    }
}
