import React from "react"
import {Button, Col, Input, InputGroup, InputGroupAddon, ListGroupItem, Row} from "reactstrap";
import {faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SongApi} from "../../library/api-connector/ApiConnector";
import AlertModal from "../../components/AlertModal";
import SongType from "../../core/types/SongType";
import {PAGINATION_SIZE} from "../../Config";
import Checker from "../../library/Checker";
import ListGroup from "reactstrap/lib/ListGroup";
import AutoGrow from "../../components/AutoGrow";

type propType = {
    changeSelectedSongs: (songIds: number[]) => void
}

type stateType = {
    songs: SongType[],
    selectedSongs: SongType[],
    search: string,
    songCount: number,
}

export default class BookMakerSong extends React.Component<propType, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            songs: [],
            selectedSongs: [],
            search: "",
            songCount: 1
        };
        this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
        this.loadSongs = this.loadSongs.bind(this);
        this.loadMoreSongs = this.loadMoreSongs.bind(this);
        this.addSong = this.addSong.bind(this);
        this.deleteSong = this.deleteSong.bind(this);
    }

    componentDidMount() {
        this.loadSongs()
    }

    onSearchKeyPress(e: React.KeyboardEvent) {
        if (Checker.isEnter(e))
            this.loadSongs();
    }

    loadSongs() {
        SongApi.listSong({
            limit: PAGINATION_SIZE,
            offset: 0,
            search: this.state.search
        })
            .then(song => this.setState({songs: song.data, songCount: song.count}))
            .catch(() => AlertModal.show({
                header: "Loading song list failed",
                message: "Song list could not be loaded"
            }))
    }

    loadMoreSongs() {
        return new Promise(resolve => {
            SongApi.listSong({
                limit: PAGINATION_SIZE,
                offset: this.state.songs.length,
                search: this.state.search
            })
                .then(song => {
                    const songs = this.state.songs.concat(song.data);
                    this.setState({songs, songCount: song.count}, resolve);
                });
        });
    }

    addSong(song: SongType) {
        const selectedSongs = this.state.selectedSongs;
        if (this.state.selectedSongs.filter(s => s.songId === song.songId).length === 0) {
            selectedSongs.push(song);
            selectedSongs.sort((a, b) => {
                if (a.title !== b.title)
                    return a.title.localeCompare(b.title);
                else
                    return a.artist.localeCompare(b.artist);
            })
        }
        this.setState({selectedSongs});
        this.props.changeSelectedSongs(selectedSongs.map(s => s.songId!));
    }

    deleteSong(song: SongType) {
        const selectedSongs = this.state.selectedSongs.filter(s => s.songId !== song.songId);
        this.setState({selectedSongs});
        this.props.changeSelectedSongs(selectedSongs.map(s => s.songId!));
    }

    render() {
        return (
            <Row className="mb-3">
                <Col xs={12} lg={6}>
                    <h3 className="mt-2">Search for songs</h3>
                    <InputGroup className="mb-2">
                        <Input type="text" color="muted" placeholder="Search" className="text-center"
                               value={this.state.search} onKeyDown={this.onSearchKeyPress}
                               onChange={e => this.setState({search: e.target.value})}/>
                        <InputGroupAddon addonType="append">
                            <Button color="primary" onClick={this.loadSongs}>
                                <FontAwesomeIcon icon={faSearch}/>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                    <ListGroup>
                        {this.state.songs.map((s, i) => {
                            return (
                                <ListGroupItem key={i}>
                                    <Row xs={10}>
                                        <Col>
                                            <div style={{fontWeight: "bold"}}>
                                                {s.title}
                                            </div>
                                            <div>
                                                by {s.artist}
                                            </div>
                                        </Col>
                                        <Col xs={2} className="m-auto">
                                            <Button color="primary" className=""
                                                    onClick={() => this.addSong(s)}>
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            );
                        })}
                        {this.state.songCount === 0 ? <span className="text-center">No Songs found</span> : ""}
                        <AutoGrow onGrow={this.loadMoreSongs} text="Loading more songs..." className="mt-2"
                                  hide={this.state.songs.length >= this.state.songCount}/>
                    </ListGroup>
                </Col>
                <Col xs={12} lg={6}>
                    <h3 className="mt-2">Selected Songs</h3>
                    <ListGroup>
                        {this.state.selectedSongs.map((s, i) => {
                            return (
                                <ListGroupItem key={i}>
                                    <Row xs={10}>
                                        <Col>
                                            <div style={{fontWeight: "bold"}}>
                                                {s.title}
                                            </div>
                                            <div>
                                                by {s.artist}
                                            </div>
                                        </Col>
                                        <Col xs={2} className="m-auto">
                                            <Button color="primary" className=""
                                                    onClick={() => this.deleteSong(s)}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            );
                        })}
                        {this.state.selectedSongs.length === 0 ?
                            <span className="text-center">Select a song to create a book</span> : ""}
                    </ListGroup>
                </Col>
            </Row>
        );
    }
}
