import {AxiosError} from "axios";

export function StatusErrorCatcher(error: AxiosError, callback? : (error: StatusErrorCatcherType | AxiosError) => any) : StatusErrorCatcherType | AxiosError {
    let returnVal : StatusErrorCatcherType | AxiosError;
    if (error.response && error.response.status)
        returnVal = {
            status: error.response.status,
            data: error.response.data,
            error
        };
    else
        returnVal = error;
    if (callback)
        callback(returnVal);
    return returnVal;
}

export type StatusErrorCatcherType = {
    status: number,
    data: any,
    error: AxiosError
}
