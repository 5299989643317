import SongType from "../core/types/SongType";

export default class OpenGraph {

    /**
     * Changes an open graph element
     * @param {string} id of the element to be changed
     * @param {string} content of the element to be changed
     */
    public static changeElement(id: string, content: string) {
        const tag = document.getElementById(id);
        if (!tag || tag.tagName !== "META")
            return;
        tag.setAttribute("content", content);
    }

    /**
     * Sets the default open graph values
     */
    public static setDefault() {
        document.title = "Songbook"
        this.changeElement("og:type", "website");
        this.changeElement("og:title", "Songbook");
        this.changeElement("og:description", "Songbook includes multiple songs and guitar chords. With it you can play songs directly on your guitar or create a PDF containg those songs.");
        this.changeElement("og:url", process.env.PUBLIC_URL!);
        this.changeElement("og:image", `${process.env.PUBLIC_URL}/favicon/android-chrome-512x512.png`);
    }

    /**
     * Sets a song as open graph value
     * @param {SongType} song to be set
     */
    public static setSong(song: SongType) {
        document.title = `${song.title} by ${song.artist} | Songbook`;
        this.changeElement("og:type", "music.song");
        this.changeElement("og:title", `${song.title} by ${song.artist} | Songbook`);
        this.changeElement("og:description", "Just click to open the song!");
        this.changeElement("og:url", `${process.env.PUBLIC_URL}/song/${song.songId}`);

    }
}
