import React from 'react';
import Footer from "./Footer";
import NavBar from "./NavBar";
import "./Footer.scss";
import AlertModal from "../components/AlertModal";

type propType = {
    hideNavbar?: boolean;
}

export default class Page extends React.Component<propType> {

    render() {
        return (
            <div>
                <div style={{minHeight: "calc( 100vh - 3em )", display: "flow-root", marginBottom: "3em"}}>
                    {this.props.hideNavbar ? "" :<NavBar/>}
                    {this.props.children}
                </div>
                <Footer/>
                <AlertModal/>
            </div>
        )
    }
}
