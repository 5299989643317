const LOCAL_STORAGE_ITEM= "syncLog"

export default class LogStorage {

    /**
     * Get current sync logs from local storage
     * @returns {string}
     */
    static getSyncLogs(): string {
        return window.localStorage.getItem(LOCAL_STORAGE_ITEM) || "No logs available";
    }
}
