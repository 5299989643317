const LOCAL_STORAGE_ITEM= "syncAll"

export default class ThemeStorage {

    /**
     * Get current syncAll from local storage
     * @returns {boolean}
     */
    static getSyncAll(): boolean {
        return !!window.localStorage.getItem(LOCAL_STORAGE_ITEM);
    }

    /**
     * Sets a new syncAll in local storage
     * @param {syncAll} syncAll to be saved
     */
    static setSyncAll(syncAll: boolean) {
        if (syncAll)
            window.localStorage.setItem(LOCAL_STORAGE_ITEM, "true");
        else
            window.localStorage.removeItem(LOCAL_STORAGE_ITEM);
    }
}
