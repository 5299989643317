import ChordFormattingEnumType from "../../core/types/ChordFormattingEnumType";

const LOCAL_STORAGE_ITEM= "chordFormatting"

export default class C {

    /**
     * Get chordFormatting db name from local storage
     * @returns {ChordFormattingEnumType}
     */
    static getChordFormatting(): ChordFormattingEnumType {
        return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ITEM) || "");
    }

    /**
     * Sets a new chordFormatting db name in local storage
     * @param {ChordFormattingEnumType} theme to be saved
     */
    static setChordFormatting(chordFormatting: ChordFormattingEnumType) {
        window.localStorage.setItem(LOCAL_STORAGE_ITEM, JSON.stringify(chordFormatting));
    }

    /**
     * Removes chordFormatting db name from local storage
     */
    static removeChordFormatting() {
        window.localStorage.removeItem(LOCAL_STORAGE_ITEM);
    }
}
