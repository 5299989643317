import React from "react";
import "./SongContol.scss";
import {Badge, ListGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import SongControlHelpModalItem from "./SongControlHelpModalItem";
import {
    faMinusCircle,
    faBackward,
    faEye,
    faEyeSlash,
    faForward,
    faPlusCircle,
    faQuestionCircle,
    faRobot
} from "@fortawesome/free-solid-svg-icons";

type propType = {
    toggle: () => void,
    shown: boolean
}

export default class SongControlHelpModal extends React.Component<propType> {

    render() {
        return (

            <Modal isOpen={this.props.shown} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    Song Controls
                </ModalHeader>
                <ModalBody>
                    <ListGroup flush>
                       <div>
                           The auto scroll panel helps to play the song easier.
                           It can be controlled by buttons or with the help of keyboard shortcuts.
                           The meaning of the <Badge color="primary">buttons</Badge> and <Badge color="info">keyboard shortcuts</Badge> is explained below.
                       </div>
                        <hr className="m-0 mt-2"/>
                        <SongControlHelpModalItem button={faMinusCircle} keyboard="Page Down"
                                                  text="Decrease text size"/>
                        <SongControlHelpModalItem button={faPlusCircle} keyboard="Page Up"
                                                  text="Increase text size"/>
                        <SongControlHelpModalItem button={faRobot} keyboard={["Space", "Enter"]}
                                                  text="Enable/Disable auto scrolling"/>
                        <SongControlHelpModalItem button={faBackward} keyboard="-"
                                                  text="Decrease scrolling speed"/>
                        <SongControlHelpModalItem button={faForward} keyboard="+"
                                                  text="Increase scrolling speed"/>
                        <SongControlHelpModalItem button={faQuestionCircle} keyboard="F1"
                                                  text="Show this help menu"/>
                        <SongControlHelpModalItem button={[faEyeSlash, faEye]} keyboard="Escape"
                                                  text="Toggle visibility song control"/>
                    </ListGroup>
                </ModalBody>
            </Modal>
        );
    }
}
