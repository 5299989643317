import React from "react";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import UserType from "../../core/types/UserType";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import {UserApi} from "../../library/api-connector/ApiConnector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import UserRolesEnumType, {UserRolesEnumTypeArray} from "../../core/types/UserRolesEnumType";
import AlertModal from "../../components/AlertModal";
import Hasher from "../../core/libraries/Hasher";
import moment from "moment";
import ChordFormattingEnumType, { ChordFormattingEnumTypeArray } from "../../core/types/ChordFormattingEnumType";

type routeComponentParameter = {
    userId: string
}

type stateType = UserType & {
    state: "loading" | "finished" | "error",
}

class UserView extends React.Component<RouteComponentProps<routeComponentParameter>, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            state: "loading",
            mail: "",
            firstName: "",
            lastName: "",
            chordFormatting: ChordFormattingEnumType.Felix
        };
        this.updateData = this.updateData.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    componentDidMount() {
        UserApi.getUser(parseInt(this.props.match.params.userId))
            .then(user => this.setState(Object.assign(user, {password: "", state: "finished"}) as stateType))
            .catch(() => this.setState({state: "error"}))
    }

    updateData() {
        UserApi.updateUserData(parseInt(this.props.match.params.userId), this.state)
            .then(() => AlertModal.show({
                header: "Account updated",
                message: "Account was successfully updated!"
            }))
            .catch(() => AlertModal.show({
                header: "Account update failed",
                message: "Account could not be updated!"
            }));
    }

    updatePassword() {
        const password = Hasher.hash(this.state.password!);
        UserApi.updateUserPassword(parseInt(this.props.match.params.userId), password)
            .then(() => AlertModal.show({
                header: "Password updated",
                message: "Password was successfully updated!"
            }))
            .catch(() => AlertModal.show({
                header: "Password update failed",
                message: "Password could not be updated!"
            }));
    }

    deleteAccount() {
        AlertModal.show({
            header: "Delete account?",
            subHeader: "All personal data will be lost forever!",
            message: "Do you really want to delete your account?",
            buttons: [{
                text: "Yes",
                color: "danger",
                handler: () => {
                    UserApi.deleteUser(parseInt(this.props.match.params.userId))
                        .then(() => {
                            window.location.assign(`${process.env.PUBLIC_URL}/admin/user`)
                        }).catch(() => AlertModal.show({
                        header: "Account deletion failed!",
                        message: "Account could not be deleted!"
                    }))
                }
            }, {
                text: "No",
                color: "secondary"
            }]
        })
    }

    render() {
        switch (this.state.state) {
            case "loading":
                return (
                    <Container className="my-3">
                        <div className="text-center">
                            <Spinner color="primary"/>
                            <span className="ml-2 vertical-super">Loading user...</span>
                        </div>
                    </Container>
                );
            case "finished":
            case "error":
                return (
                    <Container className="my-3">
                        <h1>Edit User</h1>
                        <Form className="mb-2">
                            <Row>
                                <Label xs={12} sm={2} for="lastLogin">Last login: </Label>
                                <Col xs={12} sm={10} className="m-auto">
                                    {this.state.lastLogin ?
                                        <span>
                                    <span>{moment(this.state.lastLogin).fromNow()}</span>
                                    <span className="ml-2">({moment(this.state.lastLogin).format("DD.MM.YYYY HH:mm:ss")})</span>
                                        </span>
                                        : "Never logged in"}
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="mail">Mail: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="email" name="title" value={this.state.mail} disabled
                                           onChange={e => this.setState({mail: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="firstName">First name: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="text" name="firstName" value={this.state.firstName}
                                           onChange={e => this.setState({firstName: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="lastName">Last name: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="text" name="lastName" value={this.state.lastName}
                                           onChange={e => this.setState({lastName: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="userRole">Role: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="select" name="userRole" value={this.state.userRole}
                                           onChange={e => this.setState({userRole: e.target.value as UserRolesEnumType})}>
                                        {UserRolesEnumTypeArray.filter(r => r !== UserRolesEnumType.PUBLIC).map((r, i) =>
                                            <option key={i} value={r}>{`${r.slice(0, 1)}${r.slice(1, r.length).toLocaleLowerCase()}`}</option>
                                        )}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label xs={12} sm={2} for="chordFormatting">Chord Formatting: </Label>
                                <Col xs={12} sm={10}>
                                    <Input type="select" name="chordFormatting" value={this.state.chordFormatting}
                                           onChange={e => this.setState({chordFormatting: e.target.value as ChordFormattingEnumType})}>
                                        {ChordFormattingEnumTypeArray.map((c, i) =>
                                            <option key={i} value={c}>{c}</option>
                                        )}
                                    </Input>
                                    <div className={this.state.chordFormatting === ChordFormattingEnumType.Felix ? "mt-1" : "d-none"}>
                                        Chord formatting for chord previews provided by <a target="_blank" rel="noopener noreferrer" href="https://www.felix-franz.com">Felix Franz</a>.
                                    </div>
                                    <div className={this.state.chordFormatting === ChordFormattingEnumType["T-vK"] ? "mt-1" : "d-none"}>
                                        Chord formatting for chord previews provided by <a target="_blank" rel="noopener noreferrer" href="https://github.com/T-vK/chord-collection">T-vK</a>.
                                    </div>
                                    {/* <div className={this.state.chordFormatting === ChordFormattingEnumType.jGuitar ? "mt-1" : "d-none"}>
                                        Chord formatting for chord previews delivered from <a target="_blank" rel="noopener noreferrer" href="https://jguitar.com/">jGuitar</a>.
                                    </div> */}
                                </Col>
                            </FormGroup>
                            <div className="text-right">
                                <Button color="primary" onClick={this.updateData}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2"/> Save
                                </Button>
                            </div>
                        </Form>
                        <Form className="mb-3">
                            <FormGroup row>
                                <Label xs={12} sm={2} for="password">Password:</Label>
                                <Col xs={12} sm={10}>
                                    <Input type="password" name="password" value={this.state.password}
                                           onChange={e => this.setState({password: e.target.value})}/>
                                </Col>
                            </FormGroup>
                            <div className="text-right">
                                <Button color="primary" onClick={this.updatePassword}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2"/> Save
                                </Button>
                            </div>
                        </Form>
                        <div className="text-right">
                            <Button color="danger" onClick={this.deleteAccount}>
                                <FontAwesomeIcon icon={faTrash} className="mr-2"/> Delete Account
                            </Button>
                        </div>
                    </Container>
                );
        }
    }
}

export default withRouter(UserView);
