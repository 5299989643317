import React from "react";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import UserList from "./UserList";
import AdminUserView from "./UserView";
import UserNew from "./UserNew";

type routeComponentParameter = {
    userId: string
}

class User extends React.Component<RouteComponentProps<routeComponentParameter>> {

    render() {
        const userId = this.props.match.params.userId
        if (!userId)
            return <UserList/>;
        else if (userId === "new")
            return <UserNew/>;
        else
            return <AdminUserView/>;
    }
}

export default withRouter(User);
