import React from 'react';
import "./Footer.scss"

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer box-shadow">
                <span>Copyright &copy; 2020 - {new Date().getFullYear()} <a target="_blank" rel="noopener noreferrer" href={`https://www.felix-franz.com`}>Felix Franz</a> | <a target="_blank" rel="noopener noreferrer" href={`https://www.felix-franz.com/about`}>About</a></span>
            </footer>
        );
    }
}
