import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import OfflineChecker from "../../OfflineChecker";

export default class AccountApi {
    /**
     * Gets backend version
     * @returns {string} backend version
     */
    static async getVersion(): Promise<string> {
        if (!await OfflineChecker.isOffline())
            return await new Promise((resolve, reject) => {
                Requester({
                    method: 'GET',
                    url: "/version"
                })
                    .then(response => resolve(response.data))
                    .catch(error => StatusErrorCatcher(error, reject))
            });
        else
            return "no backend connected";
    }
}
