import React, { ChangeEvent } from "react";
import { Button, ButtonGroup, Col, Collapse, CustomInput, Form, FormGroup, Input, Label } from "reactstrap";
import { LastSyncStorage, LogStorage, SongStorage, SyncAllStorage } from "../../library/Storage/Storage";
import Synchronizer from "../../Synchronizer";
import OfflineChecker from "../../library/OfflineChecker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";

type stateType = {
    syncAll: boolean,
    lastSync?: Date | null
    offlineSongCount?: number,
    isOffline: boolean
    showLogs: boolean
    logs: string
    logRefresher?: NodeJS.Timer
}

export default class OptionsSync extends React.Component<{}, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            syncAll: SyncAllStorage.getSyncAll(),
            lastSync: LastSyncStorage.getLastSync(),
            isOffline: true,
            showLogs: false,
            logs: "Loading logs...",
        };
        this.onToggleSyncAll = this.onToggleSyncAll.bind(this);
        this.onShowLogs = this.onShowLogs.bind(this);
    }

    async componentDidMount() {
        OfflineChecker.isOffline().then(isOffline => this.setState({ isOffline }));
        this.setState({
            offlineSongCount: (await SongStorage.getSongList({})).count,
        })
    }

    componentWillUnmount(): void {
        if (this.state.logRefresher)
            clearInterval(this.state.logRefresher);
    }

    onToggleSyncAll(e: ChangeEvent<HTMLInputElement>) {
        const syncAll = !this.state.syncAll;
        this.setState({ syncAll });
        SyncAllStorage.setSyncAll(syncAll);

        Synchronizer.sync(true)
    }

    onShowLogs() {
        const refreshLog = () => {
            const logs = LogStorage.getSyncLogs();
            this.setState({ logs });
        }

        let showLogs = !this.state.showLogs;
        this.setState({ showLogs }, () => {
            if (showLogs) {
                refreshLog();
                this.setState({
                    logRefresher: setInterval(refreshLog, 1000)
                })
            } else if (this.state.logRefresher) {
                clearInterval(this.state.logRefresher)
                this.setState(({ logRefresher: undefined }))
            }
        })

    }

    render() {
        return (<Form>
            <FormGroup row>
                <Label xs={12} sm={2} for="syncAll">Sync all:</Label>
                <Col xs={12} sm={10} className="py-2">
                    <CustomInput type="switch" name="syncAll" disabled={this.state.isOffline}
                        label={
                            "Save all songs offline" +
                            (
                                this.state.lastSync !== null && typeof(this.state.lastSync) !== "undefined" ?
                                    ` (synced${typeof(this.state.offlineSongCount) !== "undefined" ? ` ${this.state.offlineSongCount} songs` : ""} on ${this.state.lastSync.toLocaleString()})` :
                                    " (nothing synced here)"
                            ) +
                            (this.state.isOffline ? " - Go online to change this option!" : "")
                        }
                        id="syncAll" checked={this.state.syncAll} onChange={this.onToggleSyncAll} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label xs={12} sm={2} for="syncAll">Sync:</Label>
                <Col xs={12} sm={10}>
                    <ButtonGroup>
                        <Button color="primary" onClick={() => Synchronizer.sync(true)}>Sync now</Button>
                        <Button onClick={this.onShowLogs}>
                            Sync Logs
                            <FontAwesomeIcon className="ml-2" icon={this.state.showLogs ? faChevronCircleUp : faChevronCircleDown} />
                        </Button>
                    </ButtonGroup>
                    <Collapse isOpen={this.state.showLogs}>
                        <Input type="textarea" name="logs" value={this.state.logs} disabled={true} className="p-1"
                            style={{
                                fontFamily: '"Roboto Mono", monospace',
                                height: "15em",
                                fontSize: "0.6rem"
                            }} />
                    </Collapse>
                </Col>
            </FormGroup>
        </Form>)
    }
}
