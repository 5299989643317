import { AlwaysOfflineStorage } from "./Storage/Storage";
import {DispatcherApi} from "./api-connector/ApiConnector";

export default class OfflineChecker {
    private static readonly TIME_INVALIDATE = 30 * 1000 // time after offline status needs to be updated before it will be returned
    private static readonly TIME_UPDATE = 10 * 1000 // time after offline status will be updated after it was returned
    private static readonly LISTENER_NAME = "offline-check-completed";
    private static lastCheck = 0;
    private static offline: boolean;
    private static running: boolean;

    /**
     * Checks if the app is offline by checking the api
     */
    private static async checkOffline(): Promise<boolean> {
        this.running = true;
        try {
            await DispatcherApi.getDispatcher();
            this.offline = false;
            return false;
        } catch (e) {
            this.offline = true;
            return true;
        } finally {
            this.lastCheck = new Date().valueOf();
            this.running = false;
            window.dispatchEvent(new Event(this.LISTENER_NAME));
        }
    }

    static async handleWait(){
        window.removeEventListener(this.LISTENER_NAME, this.handleWait);
    }

    /**
     * Checks if app runs in offline mode
     * @returns {boolean} offline or not
     */
    static async isOffline(): Promise<boolean> {
        if (AlwaysOfflineStorage.getAlwaysOffline())
            return true;

        const currentCheck = new Date().valueOf();
        const elapsedTime = currentCheck - this.lastCheck;
        if (elapsedTime > this.TIME_INVALIDATE && this.running){
            return new Promise(resolve => {
                const handler = () => {
                    resolve(this.offline);
                    window.removeEventListener(this.LISTENER_NAME, handler);
                };
                window.addEventListener(this.LISTENER_NAME, handler);
            });
        } else if (elapsedTime > this.TIME_INVALIDATE)
            return this.checkOffline();
        else if (elapsedTime > this.TIME_UPDATE) {
            this.checkOffline();
            return this.offline;
        } else
            return this.offline;

    }
}
