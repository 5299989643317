import React from 'react';
import {VersionApi} from "../../library/api-connector/ApiConnector";

type stateType = {
    frontend: string,
    backend: string,
}

export default class Version extends React.Component<any, stateType> {
    constructor(props: any) {
        super(props);
        this.state = {
            frontend: process.env["REACT_APP__version"] || "unknown",
            backend: "unknown"
        }
    }

    async componentDidMount() {
        this.setState({backend: await VersionApi.getVersion()})
    }

    render() {
        return (
            <div className="text-center my-2 text-muted" style={{fontSize: "0.5em", lineHeight: "1em", position: "relative"}}>
                frontend version: {this.state.frontend}
                <br/>
                backend version: {this.state.backend}
            </div>
        );
    }
}
