import {StatusErrorCatcher} from "../ApiConnectorUtil";
import {Requester} from "../ApiConnector";
import {BookBuilderConfig} from "../../../core/types/BookBuilderTypes";

export type BookmakerApiParameters = BookBuilderConfig & {
    songIds?: number[];
    playlistId?: number;
    categoryId?: number;
}

export default class BookmakerApi {
    /**
     * Downloads book as pdf
     * @param {BookmakerApiParameters} parameter for the book creation
     * @returns {string} base64 encoded pdf
     */
    static async downloadBook(parameter: BookmakerApiParameters = {}): Promise<string> {
        const param = parameter as BookmakerApiParameters & { songId?: string; };

        if (param.songIds && param.songIds.length > 0)
            param.songId = `[${param.songIds.join(",")}]`;
        delete param.songIds
        if (!param.twosideprint)
            delete param.twosideprint;
        return new Promise((resolve, reject) => {
            Requester({
                method: 'GET',
                url: "/bookmaker",
                params: param
            })
                .then(response => resolve(response.data.pdf))
                .catch(error => StatusErrorCatcher(error, reject))
        });
    }
}
