const LOCAL_STORAGE_ITEM= "alwaysOffline"

export default class ThemeStorage {

    /**
     * Get current alwaysOffline from local storage
     * @returns {boolean}
     */
    static getAlwaysOffline(): boolean {
        return !!window.localStorage.getItem(LOCAL_STORAGE_ITEM);
    }

    /**
     * Sets a new alwaysOffline in local storage
     * @param {alwaysOffline} alwaysOffline to be saved
     */
    static setAlwaysOffline(alwaysOffline: boolean) {
        if (alwaysOffline)
            window.localStorage.setItem(LOCAL_STORAGE_ITEM, "true");
        else
            window.localStorage.removeItem(LOCAL_STORAGE_ITEM);
    }
}
