import React from 'react';
import {Alert, Button, Form, FormGroup, Input, Label} from "reactstrap";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ApiConnector from "../../library/api-connector/ApiConnector";
import LoginApi from "../../library/api-connector/api/LoginApi";
import loginRedirect from "./LoginRedirect";
import {LoginStorage, RolesStorage, ChordFormattingStorage} from "../../library/Storage/Storage";
import Checker from "../../library/Checker";
import LoginType from "../../core/types/LoginType";
import Hasher from "../../core/libraries/Hasher";
import logo from "../../core/resources/Songbook.svg";
import OfflineChecker from "../../library/OfflineChecker";

type stateType = {
    status: "automaticLogin" | "default" | "loginError",
    mail: string,
    password: string,
}

export default class Login extends React.Component<{}, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            status: "automaticLogin",
            mail: "",
            password: ""
        };
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismissAlert = this.onDismissAlert.bind(this);
    }

    async componentDidMount() {
        if (ApiConnector.hasLogin()) {
            if (! await OfflineChecker.isOffline())
                LoginApi.check()
                    .then(data => {
                        RolesStorage.setRoles(data.roles);
                        ChordFormattingStorage.setChordFormatting(data.chordFormatting);
                        loginRedirect();
                    })
                    .catch(() => {
                        ApiConnector.removeLogin();
                        LoginStorage.removeLogin();
                        this.setState({status: "default"});
                    });
            else
                loginRedirect();
        } else
            this.setState({status: "default"});
    }

    onKeyPress(e: React.KeyboardEvent) {
        if (Checker.isEnter(e) && this.state.mail !== "" && this.state.password !== "")
            this.onSubmit(e);
    }


    onSubmit(e: React.MouseEvent | React.KeyboardEvent) {
        e.preventDefault();
        const login: LoginType = Hasher.hashLogin({
            mail: this.state.mail,
            password: this.state.password
        });

        ApiConnector.setLogin(login);
        LoginApi.check()
            .then(data => {
                LoginStorage.setLogin(login);
                RolesStorage.setRoles(data.roles);
                ChordFormattingStorage.setChordFormatting(data.chordFormatting);
                this.setState({status: "default"});
                loginRedirect();
            })
            .catch(() => {
                ApiConnector.removeLogin();
                this.setState({status: "loginError"});
            });
    }

    onDismissAlert() {
        this.setState({status: "default"});
    }


    render() {
        return (
            <div className="m-auto" style={{textAlign: "center", maxWidth: "29em"}}>
                <img className="m-2 mt-5" src={logo} style={{maxHeight: "15em", height: "100%"}} alt="Logo"/>
                <div style={{display: this.state.status === "automaticLogin" ? "none" : undefined}}>
                    <h1 className="mt-3 mb-0">Login</h1>
                    <p className="text-muted mt-0">Sign in to get access to the one and only songbook!</p>
                    <Form>
                        <FormGroup className="m-2">
                            <Label for="mail" className="mr-sm-2">E-Mail</Label>
                            <Input name="mail" type="email" onChange={e => this.setState({mail: e.target.value})}
                                   onKeyPress={this.onKeyPress} className="text-center"/>
                        </FormGroup>
                        <FormGroup className="m-2">
                            <Label for="password" className="mr-sm-2">Passwort</Label>
                            <Input name="password" type="password"
                                   onChange={e => this.setState({password: e.target.value})}
                                   onKeyPress={this.onKeyPress} className="text-center"/>
                        </FormGroup>
                        <Alert className="m-2" color="danger" isOpen={this.state.status === "loginError"}
                               toggle={this.onDismissAlert}>
                            Mail or passwort are not correct!
                        </Alert>
                        <Button className="m-2" color="link" href={`${process.env.PUBLIC_URL}/login/reset`}>
                            Reset password
                        </Button>
                        <Button className="m-2" color="primary" onClick={this.onSubmit}
                                disabled={this.state.mail === null || this.state.mail === "" ||
                                this.state.password === null || this.state.password === ""}>Sign In</Button>
                    </Form>

                </div>
                <div className="mt-3" style={{display: this.state.status !== "automaticLogin" ? "none" : undefined}}>
                    <Alert color="primary"><FontAwesomeIcon icon={faCircleNotch} spin/> We are signing you in right
                        now...</Alert>
                </div>
            </div>
        );
    }
}
