export default class PreviewConverter {

    /**
     * Converts playable string to input string
     * @param {string} data playable url
     * @returns {string} common url
     */
    static toInput(data?: string): string {
        if (!data)
            return "";
        if (data.match(/^https?:\/\/(www\.)?youtube.com\/embed\/.+$/)) {
            const videoId = data.match(/^https?:\/\/(www\.)?youtube.com\/embed\/(.+)$/)![2];
            return `https://youtube.com/watch?v=${videoId}`
        } else
            return data;
    }

    /**
     * Converts input string to playable string
     * @param {string} common url
     * @returns {string} data playable url
     */
    static toPlayable(data: string): string {
        if (data.match(/^https?:\/\/(www\.)?youtube.com\/watch\?v=.+$/)) {
            const videoId = data.match(/https?:\/\/(www\.)?youtube.com\/watch\?v=(.+)$/)![2];
            console.log(`https://youtube.com/embed/${videoId}`)
            return `https://youtube.com/embed/${videoId}`
        } else
            return data;
    }
}
