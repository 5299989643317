import React from 'react';
import {Alert, Button, Form, FormGroup, Input, Label} from "reactstrap";
import {faEnvelopeOpenText, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoginApi from "../../library/api-connector/api/LoginApi";
import Checker from "../../library/Checker";
import logo from "../../core/resources/Songbook.svg";

type stateType = {
    status: "default" | "success" | "error",
    mail: string,
}

export default class LoginResetCreate extends React.Component<{}, stateType> {

    constructor(props: any) {
        super(props);
        this.state = {
            status: "default",
            mail: "",
        };
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismissAlert = this.onDismissAlert.bind(this);
    }

    onKeyPress(e: React.KeyboardEvent) {
        if (Checker.isEnter(e) && this.state.mail !== "")
            this.onSubmit(e);
    }


    onSubmit(e: React.MouseEvent | React.KeyboardEvent) {
        e.preventDefault();

        LoginApi.createReset(this.state.mail)
            .then(data => {
                this.setState({status: "success"});
            })
            .catch(() => {
                this.setState({status: "error"});
            });
    }

    onDismissAlert() {
        this.setState({status: "default"});
    }


    render() {
        return (
            <div className="m-auto" style={{textAlign: "center", maxWidth: "29em"}}>
                <img className="m-2 mt-5" src={logo} style={{maxHeight: "15em", height: "100%"}} alt="Logo"/>
                <div style={{display: this.state.status === "success" ? "none" : undefined}}>
                    <h1 className="mt-3 mb-0">Password reset</h1>
                    <p className="text-muted mt-0">Type in you mail and we sent you a link to reset your password!</p>
                    <Form>
                        <FormGroup className="m-2">
                            <Label for="mail" className="mr-sm-2">E-Mail</Label>
                            <Input name="mail" type="email" onChange={e => this.setState({mail: e.target.value})}
                                   onKeyPress={this.onKeyPress}/>
                        </FormGroup>
                        <Alert className="m-2" color="danger" isOpen={this.state.status === "error"}
                               toggle={this.onDismissAlert}>
                            <FontAwesomeIcon icon={faExclamationCircle}/> Password reset failed!
                        </Alert>
                        <Button className="m-2" color="link" href={`${process.env.PUBLIC_URL}/login`}>
                            Sign in
                        </Button>
                        <Button className="m-2" color="primary" onClick={this.onSubmit}
                                disabled={this.state.mail === null || this.state.mail === ""}>Send reset link</Button>
                    </Form>

                </div>
                <div className="mt-3" style={{display: this.state.status !== "success" ? "none" : undefined}}>
                    <Alert color="success">
                        <FontAwesomeIcon icon={faEnvelopeOpenText}/> Check your mails! We have sent you a link to reset your password!
                    </Alert>
                </div>
            </div>
        );
    }
}
