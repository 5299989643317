import React from "react";
import "./SongContol.scss";
import {Button, ButtonGroup} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackward,
    faEye,
    faEyeSlash,
    faForward,
    faPlusCircle,
    faQuestionCircle, faRobot
} from "@fortawesome/free-solid-svg-icons";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import SongControlHelpModal from "./SongControlHelpModal";
import {showNavBar} from "../pages/NavBar";

type propType = {
    textSize: number,
    onChangeSize: (textSize: number) => void
}

type stateType = {
    autoScrolling: boolean,
    speed: number,
    shown: boolean,
    showHelp: boolean
}

const SPEED_STEPS = 20;
const TEXTSIZE_STEPS = 0.1;

export default class SongControl extends React.Component<propType, stateType> {
    timerId?: any;

    constructor(props: any) {
        super(props);
        this.state = {
            autoScrolling: false,
            speed: 100,
            shown: true,
            showHelp: false
        };
        this.handleKeyboardShortcut = this.handleKeyboardShortcut.bind(this);
        this.onClickAutoScroll = this.onClickAutoScroll.bind(this);
        this.enableScrolling = this.enableScrolling.bind(this);
        this.disableScrolling = this.disableScrolling.bind(this);
        this.updateScrollingSpeed = this.updateScrollingSpeed.bind(this);
        this.updateTextSize = this.updateTextSize.bind(this);
        this.scroll = this.scroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyboardShortcut);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyboardShortcut);
    }

    handleKeyboardShortcut(e: KeyboardEvent) {
        if ((e.target as HTMLElement).tagName.toLowerCase() !== "body")
            return;

        let preventDefault: boolean = true;

        switch (e.key) {
            case " ":
            case "Enter":
                this.onClickAutoScroll();
                break;
            case "-":
                this.updateScrollingSpeed(false);
                break;
            case "+":
                this.updateScrollingSpeed(true);
                break;
            case "PageDown":
                this.updateTextSize(false)
                break;
            case "PageUp":
                this.updateTextSize(true)
                break;
            case "Escape":
                showNavBar(!this.state.shown);
                this.setState({shown: !this.state.shown});
                break;
            case "F1":
                this.setState({showHelp: !this.state.showHelp});
                break;
            default:
                preventDefault = false;
        }
        if (preventDefault)
            e.preventDefault();
    }

    onClickAutoScroll() {
        if (this.state.autoScrolling)
            this.disableScrolling();
        else
            this.enableScrolling();
        this.setState({autoScrolling: !this.state.autoScrolling});
    }

    enableScrolling() {
        this.disableScrolling();
        this.timerId = setInterval(this.scroll, this.state.speed);
    }

    disableScrolling() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            delete this.timerId;
        }
    }

    updateScrollingSpeed(increase: boolean) {
        const speed = increase ? this.state.speed - SPEED_STEPS : this.state.speed + SPEED_STEPS;
        this.setState({speed}, this.enableScrolling)
    }

    updateTextSize(increase: boolean) {
        this.props.onChangeSize(this.props.textSize + TEXTSIZE_STEPS * (increase ? 1 : -1))
    }

    scroll() {
        window.scroll(0, window.pageYOffset + 1);
        if (document.body.scrollHeight - window.innerHeight - window.pageYOffset <= 5)
            this.disableScrolling();
    }

    render() {
        return (
            <div className="song-control">
                <div
                    className={`song-control-bar song-control-box text-center ${this.state.shown ? "" : "song-control-bar-hide"}`}>
                    <ButtonGroup className="m-1">
                        <Button color="primary" size="sm"
                                onClick={() => this.updateTextSize(false)}>
                            <FontAwesomeIcon icon={faMinusCircle}/>
                        </Button>
                        <Button color="primary" size="sm"
                                onClick={() => this.updateTextSize(true)}>
                            <FontAwesomeIcon icon={faPlusCircle}/>
                        </Button>
                        <Button color={this.state.autoScrolling ? "success" : "danger"} size="sm"
                                onClick={this.onClickAutoScroll}>
                            <FontAwesomeIcon icon={faRobot} className="mr-2"/>
                            {this.state.autoScrolling ? "Scrolling" : "Autoscroll"}
                        </Button>
                        <Button color="primary" size="sm" onClick={() => this.updateScrollingSpeed(false)}>
                            <FontAwesomeIcon icon={faBackward}/>
                        </Button>
                        <Button color="primary" size="sm" onClick={() => this.updateScrollingSpeed(true)}>
                            <FontAwesomeIcon icon={faForward}/>
                        </Button>
                        <Button color="secondary" size="sm"
                                onClick={() => this.setState({showHelp: !this.state.showHelp})}>
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </Button>
                        <Button color="secondary" size="sm" onClick={() => this.setState({shown: false}, () => showNavBar(false))}>
                            <FontAwesomeIcon icon={faEyeSlash}/>
                        </Button>
                    </ButtonGroup>
                </div>
                <div
                    className={`song-control-toggler song-control-box ${this.state.shown ? "song-control-toggler-hide" : ""}`}>
                    <Button color="primary" size="sm" onClick={() => this.setState({shown: true}, () => showNavBar(true))}>
                        <FontAwesomeIcon icon={faEye}/>
                    </Button>
                </div>
                <SongControlHelpModal shown={this.state.showHelp}
                                      toggle={() => this.setState({showHelp: !this.state.showHelp})}/>
            </div>
        );
    }
}
